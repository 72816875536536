import Button from "css/modules/Button.module.css";

import { useEffect } from "react";
import Navbar from "components/navigation/Navbar";

function Register() {
  useEffect(() => {
    document.body.setAttribute("data-theme", "light");
  });

  return (
    <>
      <Navbar color={"#fff"} />
      <div class="grid grid-cols-1 lg:grid-cols-2 h-dvh">
        <div class="bg-[#011142] hidden lg:block"></div>
        <div className="flex flex-col max-w-md mx-auto p-4 h-full items-center justify-center">
          <h2 className="text-2xl font-bold mb-4 text-center">Register</h2>
          <form>
            <div className="mb-4 flex gap-2">
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="firstName"
                >
                  First Name
                </label>
                <input
                  className="shadow appearance-none border rounded-xl w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                />
              </div>
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="lastName"
                >
                  Last Name
                </label>
                <input
                  className="shadow appearance-none border rounded-xl w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="shadow appearance-none border rounded-xl w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                placeholder="Email"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                className="shadow appearance-none border rounded-xl w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder="Password"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="terms"
              >
                <input
                  className="mr-2 leading-tight cursor-pointer"
                  id="terms"
                  type="checkbox"
                />
                <span class="font-normal">
                  I agree to the{" "}
                  <a
                    href="/terms-of-service"
                    class="text-blue-50 hover:text-blue-100"
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    href="/privacy-policy"
                    class="text-blue-50 hover:text-blue-100"
                  >
                    Privacy Policy
                  </a>
                </span>
              </label>
            </div>
            <div className="flex items-center justify-between w-full">
              <button className={`${Button.default} w-full`} type="button">
                Sign up
              </button>
            </div>
          </form>
          <div class="w-full text-center mt-4">or</div>
        </div>
      </div>
    </>
  );
}

export default Register;
