import Style from "css/pages/Home.module.css";
import ButtonStyle from "css/modules/Button.module.css";
import Keyframes from "css/modules/Keyframes.module.css";

import { siteName } from "lib/info";

import { useEffect } from "react";
import Navbar from "components/navigation/Navbar";
import LinedBackground from "components/designs/LinedBackground";

function Home() {
  useEffect(() => {
    document.body.setAttribute("data-theme", "light");
  });

  return (
    <>
      <Navbar />
      <LinedBackground />
      <div className={Style.content}>
        <div class="max-w-screen-md text-center">
          <h1
            className={Keyframes.slideInUpFadeIn}
            style={{ animationDuration: "0.5s" }}
          >
            Your Secure, Private, and Compliant AI
          </h1>
          <p
            className={`${Keyframes.slideInUpFadeIn} max-w-xl mx-auto`}
            style={{ animationDuration: "0.65s" }}
          >
            Welcome to {siteName}. We provide AI solutions that are secure,
            private, and compliant with regulations.
          </p>
          <div
            className={`${Style.items} ${Keyframes.slideInUpFadeIn}`}
            style={{ animationDuration: "0.65s" }}
          >
            <a
              href="/redesign/register"
              aria-label="Get Started"
              className={ButtonStyle.default}
            >
              Get Started
            </a>
            <a
              href="#/redesign/contact"
              aria-label="Contact Us"
              className={ButtonStyle.secondary}
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
