import { useState, useEffect, useReducer } from "react";
import { fetchJson } from "../utility/fetchJson";
import {
  connectSocket,
  emitToServer,
  addEventListener,
  removeEventListener,
  disconnectSocket,
} from "../utility/socket";

const chatReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_AI_HISTORY":
      return {
        ...state,
        chatMsgsForServer: [...state.chatMsgsForServer, action.payload],
      };

    case "ADD_TO_CHAT_DISPLAY":
      return {
        ...state,
        chatMsgsToDisplay: [...state.chatMsgsToDisplay, action.payload],
        isLoadingResponse: false,
        loadingValue: 100,
      };

    case "ADD_TO_SERVER":
      return {
        ...state,
        chatMsgsForServer: [...state.chatMsgsForServer, action.payload],
      };
    case "RESET_CHAT_DISPLAY":
      return {
        ...state,
        chatMsgsToDisplay: [],
        chatMsgsForServer: [],
        isLoadingResponse: false,
        loadingValue: 100,
        availableSuggestions: [],
      };

    case "REMOVE_FROM_CHAT_DISPLAY":
      return {
        ...state,
        chatMsgsToDisplay: state.chatMsgsToDisplay.filter(
          (msg) => msg.content !== action.payload.msg
        ),
        isLoadingResponse: false,
        loadingValue: 100,
      };

    case "AI_STREAM_RECEIVED":
      if (!state.stoppedIds.includes(action.payload.id)) {
        if (state.latestStream.content) {
          return {
            ...state,
            latestStream: {
              content: state.latestStream.content + action.payload.content,
              index: action.payload.index,
              id: action.payload.id,
            },
            isLoadingResponse: false,
            isStreaming: true,
            loadingValue: 100,

          };
        } else {
          return {
            ...state,
            latestStream: {
              content: action.payload.content,
              index: action.payload.index,
              id: action.payload.id,
            },
            isLoadingResponse: false,
            isStreaming: true,
            loadingValue: 100,

          };
        }
      } else {
        return {
          ...state,
        };
      }

    case "AI_STREAM_FINISHED": //when the stream is finished, reset stream object and add finished stream to chatMsgs array
      let requestData = {
        role: action.payload.role,
        content: action.payload.content,
        isLiked: false,
        isDisliked: false,
        responseMode:state. responseMode
      };
      return {
        ...state,
        chatMsgsForServer: [...state.chatMsgsForServer, action.payload],
        chatMsgsToDisplay: [...state.chatMsgsToDisplay, requestData],
        latestStream: {},
        isStreaming: false,
        stoppedIds: [],
        showLoading: false,

      };
    case "AI_STREAM_STOPPED":
      let stoppedRequestdata = {
        role: action.payload.role,
        content: action.payload.content,
      };
      return {
        ...state,
        chatMsgsForServer: [...state.chatMsgsForServer, stoppedRequestdata],
        chatMsgsToDisplay: [...state.chatMsgsToDisplay, action.payload],
        latestStream: {},
        isStreaming: false,
        stoppedIds: [...state.stoppedIds, action.payload.id],
        showLoading: false,

      };
    case "UPDATE_MSG_FROM_SESSION":
      return {
        ...state,
        chatMsgsForServer: action.payload.chatMsgsForServer,
        chatMsgsToDisplay: action.payload.chatMsgsToDisplay,
      };

    case "USER_MSG_UPDATE":
      return { ...state, currentUserMsg: action.payload };

    case "USER_MSG_SUBMIT":
      return {
        ...state,
        chatMsgsToDisplay: [...state.chatMsgsToDisplay, action.payload],
        chatMsgsForServer: [...state.chatMsgsForServer, action.payload],
        currentUserMsg: "",
        isLoadingResponse: true,
        loadingValue: 100,
      };

    case "USER_MSG_SUBMIT_DISPLAY_ONLY":
      return {
        ...state,
        chatMsgsToDisplay: [...state.chatMsgsToDisplay, action.payload],
        currentUserMsg: "",
        isLoadingResponse: true,
      };

    case "CHANGE_PERSONA":
      return { ...state, selectedPersona: action.payload }; //other state changes when full persona implementation

    case "SET_LOADING_PROGRESS":
      return {
        ...state,
        isLoadingResponse: true,
        loadingValue: action.payload.value,
      };

    case "SET_AI_FOLLOWUPS":
      return { ...state, aiFollowUps: action.payload?.followups };
    case "SET_LOADING":
      return { ...state, showLoading: action.payload.isLoading };
    case "SET_IS_STREAMING":
      return { ...state, isStreaming: action.payload.isStreaming };
    case "UPDATE_LAST_MSG_TO_DISPLAY_CONTENT":
      const newMsgs = [...state.chatMsgsToDisplay];

      newMsgs[newMsgs.length - 1] = {
        ...newMsgs[newMsgs.length - 1],
        keywords: action.payload.keywords,
      };
      return {
        ...state,
        chatMsgsToDisplay: newMsgs,
      };

    case "UPDATE_CHAT_MSG_BY_INDEX":
      let newDisplayMsgs = [...state.chatMsgsToDisplay];

      newDisplayMsgs[action.payload.index] = {
        ...action.payload.msg,
      };
      return {
        ...state,
        chatMsgsToDisplay: newDisplayMsgs,
      };
    case "REMOVE_MSG_BY_INDEX":
      let newChatDisplayMsgs = [...state.chatMsgsToDisplay];

      const updatedMsgs = newChatDisplayMsgs.filter(
        (msg, index) => index !== action.payload.index
      );

      return {
        ...state,
        chatMsgsToDisplay: updatedMsgs,
      };
    case "SET_AI_COMMANDS":
      let newChatMsgsToDisplay = [...state.chatMsgsToDisplay];

      const { chatIndex, commands } = action.payload;

      newChatMsgsToDisplay[chatIndex] = {
        ...newChatMsgsToDisplay[chatIndex],
        commands: commands,
        isLoadingCommands: false,
      };

      return {
        ...state,
        chatMsgsToDisplay: newChatMsgsToDisplay,
      };
    case "SET_LOADING_COMMANDS":
      let newChatMsgToDisplay = [...state.chatMsgsToDisplay];

      const { aiIndex, isLoadingCommands } = action.payload;

      newChatMsgToDisplay[aiIndex] = {
        ...newChatMsgToDisplay[aiIndex],
        commands: [],
        isLoadingCommands: isLoadingCommands,
      };
      return {
        ...state,
        chatMsgsToDisplay: newChatMsgToDisplay,
      };
    case "SET_AI_SUGGESTIONS":
      let newChatMsgs = [...state.chatMsgsToDisplay];
      const { suggIndex, suggestions } = action.payload;

      newChatMsgs[suggIndex] = {
        ...newChatMsgs[suggIndex],
        suggestions: suggestions,
        isLoadingSuggestions: false,
      };

      return {
        ...state,
        chatMsgsToDisplay: newChatMsgs,
      };
    case "SET_AI_COMMAND_LOADING":
      let updatedChatMsgs = [...state.chatMsgsToDisplay];

      const { commandIndex, isLoadingCommand } = action.payload;

      updatedChatMsgs[commandIndex] = {
        ...updatedChatMsgs[commandIndex],
        isLoadingCommands: isLoadingCommand,
      };
      return {
        ...state,
        chatMsgsToDisplay: updatedChatMsgs,
      };
    case "SET_LOADING_SUGGESTIONS":
      let newChatMsgsDisplay = [...state.chatMsgsToDisplay];

      const { index, isLoading } = action.payload;

      newChatMsgsDisplay[index] = {
        ...newChatMsgsDisplay[index],
        suggestions: [],
        isLoadingSuggestions: isLoading,
      };
      return {
        ...state,
        chatMsgsToDisplay: newChatMsgsDisplay,
      };
    case "SET_ADVIOR_CHAT_ID":
      return {
        ...state,
        [action.payload.advisorId]: action.payload.chatId,
      };
    case "SET_RESPONSE_MODE":
      return {
        ...state,
        responseMode: action.payload.responseMode,
      };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

// User && assitant
function useAI(aiRequestEventName, aiResponseEventName) {
  const [state, dispatch] = useReducer(chatReducer, {
    chatMsgsForServer: [],
    chatMsgsToDisplay: [], // keywords , commands & suggestions , content , advisorId
    latestStream: {},
    currentUserMsg: "",
    isLoadingResponse: false,
    isStreaming: false, //implement later to disable input or changes while streaming.
    aiFollowUps: [],
    showLoading: false,
    loadingValue: 0,
    stoppedIds: [],
    availableSuggestions: [],
    chatIdByAdvisor: {},
    responseMode: "sequential",
  });
  const [isLoadingHandshake, setIsLoadingHandshake] = useState(false);

  //AI response is sent in chunks - latest stream; the chunks have a "content" and "index" key.
  //when the index is -1, it means the last chunk has been sent and stream is done.
  //the finshed stream is then added to the chatMsgs array.
  useEffect(() => {
    setIsLoadingHandshake(true);
    connectSocket();
    addEventListener(aiResponseEventName, handleAIStream);
    setIsLoadingHandshake(false);
    return () => {
      removeEventListener(aiResponseEventName);
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    if (state.latestStream && state.latestStream.index === -1) {
      dispatch({
        type: "AI_STREAM_FINISHED",
        payload: {
          role: "assistant",
          content: state.latestStream.content,
        },
      });
    }
  }, [state.latestStream]);

  const handleAIStream = (streamObj) => {
    let payload = {
      content: streamObj.message,
      index: streamObj.index ? streamObj.index : "",
      id: streamObj.id ? streamObj.id : "",
    };

    dispatch({ type: "AI_STREAM_RECEIVED", payload });
  };

  const handlePersonaClick = (newPersona) => {
    dispatch({ type: "CHANGE_PERSONA", payload: newPersona });
  };

  const handleMsgChange = (e) => {
    dispatch({ type: "USER_MSG_UPDATE", payload: e.target.value });
  };

  const setAIFollowUps = (aiFollowUps) => {
    dispatch({ type: "SET_AI_FOLLOWUPS", payload: aiFollowUps });
  };
  const setLoading = (loading) => {
    dispatch({ type: "SET_LOADING", loading: loading });
  };

  const handleUserSubmit = (e, additionalFieldsToEmit = {}) => {
    e.preventDefault();
    const latestUserMsg = { role: "user", content: state.currentUserMsg };
    emitToServer(aiRequestEventName, {
      chat_msgs: [...state.chatMsgsForServer, latestUserMsg],
      persona: state.selectedPersona.name,
      ...additionalFieldsToEmit,
    });
    dispatch({ type: "USER_MSG_SUBMIT", payload: latestUserMsg });
  };

  const handleQuestionClick = (questionObj) => {
    const { content, endpoint, orientation, socket } = questionObj;
    const latestUserMsg = { role: "user", content: content };
    if (socket) {
      emitToServer(endpoint, {
        chat_msgs: [...state.chatMsgsForServer, latestUserMsg],
        persona: state.selectedPersona.name,
        orientation,
      });
      dispatch({ type: "USER_MSG_SUBMIT", payload: content });
    } else {
      fetchJson(endpoint, {}).then((data) => {});
    }
  };
  const animateLoadingProgress = async (targetValue, duration) => {
    const start = Date.now();
    let progress = 0;

    while (progress < 1) {
      progress = Math.min(1, (Date.now() - start) / duration);
      await dispatch({
        type: "SET_LOADING_PROGRESS",
        payload: { value: progress * targetValue },
      });
      await new Promise((resolve) => requestAnimationFrame(resolve));
    }
  };

  const setInitialLoadingValue = async (aiType = "") => {
    const timer = aiType === "news" ? 100 : 500;
    const finalValue = aiType === "news" ? 100 : 90;

    await new Promise((resolve) => setTimeout(resolve, timer));
    await dispatch({
      type: "SET_LOADING_PROGRESS",
      payload: { value: 25 },
    });

    await new Promise((resolve) => setTimeout(resolve, timer));
    await dispatch({
      type: "SET_LOADING_PROGRESS",
      payload: { value: 50 },
    });

    await new Promise((resolve) => setTimeout(resolve, timer));
    await dispatch({
      type: "SET_LOADING_PROGRESS",
      payload: { value: 75 },
    });
    await new Promise((resolve) => setTimeout(resolve, timer));

    await dispatch({
      type: "SET_LOADING_PROGRESS",
      payload: { value: finalValue },
    });

    await new Promise((resolve) => setTimeout(resolve, 200));
  };

  return {
    chatMsgsForServer: state.chatMsgsForServer,
    chatMsgsToDisplay: state.chatMsgsToDisplay,
    latestStream: state.latestStream,
    currentUserMsg: state.currentUserMsg,
    isLoadingHandshake,
    isLoadingResponse: state.isLoadingResponse,
    selectedPersona: state.selectedPersona,
    aiFollowUps: state.aiFollowUps,
    availableSuggestions: state.availableSuggestions,
    isLoadingSuggestions: state.isLoadingSuggestions,
    handleQuestionClick,
    dispatch,
    handlePersonaClick,
    handleMsgChange,
    handleUserSubmit,
    emitToServer,
    setAIFollowUps,
    isStreaming: state.isStreaming,
    showLoading: state.showLoading,
    loadingValue: state.loadingValue,
    removeEventListener: removeEventListener,
    handleAIStream: handleAIStream,
    setInitialLoadingValue: setInitialLoadingValue,
    chatIdByAdvisor: state.chatIdByAdvisor,
    responseMode: state.responseMode,
  };
}

export default useAI;
