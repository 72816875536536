import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import useAI from "../../hooks/useAI";
import { aiModels, helpPrompts, loadingMsgs } from "../../utils";
import axios from "axios";
import { getAPIBaseUrl } from "../../utility/constants";
import { FaFileAlt } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";
import { setChatId, setUserDetails } from "../../redux/actions/authActions";
import "rc-dropdown/assets/index.css";
import { toast } from "react-toastify";
import { googleLogout } from "@react-oauth/google";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import CoreStyle from "./css/Core.module.css";
import ContentStyle from "./css/Content.module.css";
import Content from "./components/Content";
import Sidebar from "./components/Sidebar";

const getRandomElements = (arr, numElements = 4) => {
  const shuffledArray = arr.sort(() => 0.5 - Math.random());

  return shuffledArray.slice(0, numElements);
};

const ChatScreen = () => {
  const {
    dispatch,
    chatMsgsToDisplay,
    emitToServer,
    chatMsgsForServer,
    latestStream,
    showLoading,
    isStreaming,
    responseMode
  } = useAI("ai_chat", "ai_chat");
  const { instance, accounts } = useMsal();

  const inputRef = useRef();
  const chatInputRef = useRef();
  const scrollContainerRef = useRef();
  const fileInputRef = useRef();

  const storeDispatch = useDispatch();
  const navigate = useNavigate();

  const { currentUser, userChatId, userType } = useSelector(
    (state) => state.auth
  );

  console.log("");

  console.log("currentUser>>>", currentUser);
  const { chatId } = useParams();

  const [authLoading, setAuthLoading] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [isLoginModal, setLoginModal] = useState(false);
  const [isSignUpModal, setSignUpModal] = useState(false);
  const [signupErrorMessage, setSignupErrorMessage] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [rows, setRows] = useState(1);
  const [isScrolling, setIsScrolling] = useState(false); // To track manual scrolling
  const [loadingText, setLoadingText] = useState(
    loadingMsgs[Math.floor(Math.random() * loadingMsgs.length)]
  );
  const [loadingDotsDirection, setLoadingDotsDirection] = useState("up");
  const [prompts, setPrompts] = useState(getRandomElements(helpPrompts));
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploadFileLoading, setUploadFileLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatHistoryLoading, setChatHistoryLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState("");
  const [updatedChatTitle, setUpdatedChatTitle] = useState("");
  const [isShareChatModal, setShareChatModal] = useState(false);
  const [isCopyLoading, setCopyLoading] = useState(false);
  const [selectedChatTitle, setSelectedChatTitle] = useState("");
  const [expanded, setExpanded] = useState(true);
  const [isChatMsgLoading, setChatMsgLoading] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
  const [files, setFiles] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState("");
  const [isChatHistoryExpanded, setChatHistoryExpanded] = useState(false);
  const [isFilesExpanded, setFilesExpanded] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [isListening, setListening] = useState(false);
  const [audioResponse, setResponseAudio] = useState("");
  const [currentState, setCurrentState] = useState("chat");
  const [billingInfo, setBillingInfo] = useState({});
  const [selectedAiModel, setSelectedAiModel] = useState(["gpt-4o"]);

  document.documentElement.setAttribute("data-theme", theme);
  const language = localStorage.getItem("language") || "english";

  console.log("theme>>", theme);

  const getUserBillingInfo = async () => {
    try {
      const email =
        userType === "individual"
          ? currentUser.email
          : currentUser && currentUser.user && currentUser.user.email
          ? currentUser.user.email
          : "";
      const user_type = userType;
      const enterprise_id =
        userType === "enterprise"
          ? currentUser &&
            currentUser.enterprise &&
            currentUser.enterprise.enterprise_id
            ? currentUser.enterprise.enterprise_id
            : ""
          : "";

      if (email) {
        const response = await axios.get(
          `${getAPIBaseUrl()}/get-billing-info?user_email=${email}&user_type=${user_type}&enterprise_id=${enterprise_id}`
        );

        if (response && response.data) {
          setBillingInfo(response.data);
        }
      } else {
        setBillingInfo({});
      }
    } catch (error) {
      console.error("Error fetching billing info:", error);
      setBillingInfo({});
    } finally {
    }
  };

  const uploadAudio = async (audioBlob) => {
    setListening(true);
    const formData = new FormData();
    formData.append("file", audioBlob, "audio.webm");

    try {
      const response = await fetch(`${getAPIBaseUrl()}/voice-to-text`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();

      if (data && data.transcript) {
        handleUserQuery(data.transcript);

        // const gptResponse = await fetch(`${getAPIBaseUrl()}/chat`, {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({
        //     message: [
        //       {
        //         role: "user",
        //         content: data.transcript,
        //       },
        //     ],
        //   }),
        // });
        // const { response } = await gptResponse.json();

        // const ttsResponse = await fetch(`${getAPIBaseUrl()}/text-to-speech`, {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({ text: response }),
        // });
        // const { audioUrl } = await ttsResponse.json();

        // console.log("audioUrl", audioUrl);
        // setResponseAudio(audioUrl);
        // // Play the audio using the generated URL
        // const audio = new Audio(audioUrl);
        // audio.play();
      }
      console.log("data>>", data);
    } catch (error) {
      console.error("Error uploading audio:", error);
    } finally {
      setListening(false);
    }
  };

  const navigateToSignup = () => {
    setSignUpModal(true);
    setLoginModal(false);
    setLoginErrorMessage("");
    setSignupErrorMessage("");
  };

  const navigateToLogin = () => {
    setSignUpModal(false);
    setLoginModal(true);
    setLoginErrorMessage("");
    setSignupErrorMessage("");
  };

  const saveUpdatedChatTitle = async () => {
    try {
      const response = await axios.put(`${getAPIBaseUrl()}/rename-chat`, {
        chat_id: selectedChat,
        new_title: updatedChatTitle,
      });
      toast.success("Chat title updated successfully");
      setSelectedChat("");
      setUpdatedChatTitle("");
      await getSavedChatList();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSuccess = async (response) => {
    try {
      const token = response.access_token;

      const user_info = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (user_info && Object.keys(user_info).length > 0) {
        const { name, email, given_name, family_name } = user_info.data;

        const data = {
          name: name ? name : given_name + family_name,
          email,
        };
        const response = await axios.post(
          `${getAPIBaseUrl()}/google-login`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        storeDispatch(setUserDetails(response?.data?.user));
        toast.success("Logged in successfully");
        setLoginModal(false);
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Logged in failed ! Please try again !");
    }
  };

  const signupHandler = async (email, password, name) => {
    setAuthLoading(true);

    const data = {
      email,
      password,
      name,
    };
    try {
      const response = await axios.post(`${getAPIBaseUrl()}/signup`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      storeDispatch(setUserDetails(response?.data?.user));
      setAuthLoading(false);
      setSignupErrorMessage("");
      setSignUpModal(false);
      toast.success("Sign up successfully");
    } catch (error) {
      storeDispatch(setUserDetails({}));
      setAuthLoading(false);
      setSignupErrorMessage(error?.response?.data?.error);
      toast.error("Sign up failed ! Try again !");
    }
  };

  const loginHandler = async (email, password) => {
    setAuthLoading(true);

    const data = {
      email,
      password,
    };
    try {
      const response = await axios.post(`${getAPIBaseUrl()}/login`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      storeDispatch(setUserDetails(response?.data?.user));
      setAuthLoading(false);
      setLoginErrorMessage("");
      setLoginModal(false);
      toast.success("Logged in successfully");
    } catch (error) {
      storeDispatch(setUserDetails({}));
      setAuthLoading(false);
      setLoginErrorMessage(error?.response?.data?.error);
      toast.error("Logged in failed ! Try again !");
    }
  };

  const logoutUser = async () => {
    storeDispatch(setUserDetails({}));
    googleLogout();
    resetChatToDisplay();
    navigate("/login");

    const data = {
      email: currentUser?.email,
    };
    const response = await axios.post(
      `${getAPIBaseUrl()}/revoke-all-session`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const deleteUserHandler = async () => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-user?email=${currentUser.email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("User deleted successfully!");
      logoutUser();
      setSettingsOpen(false);
    } catch (error) {
      toast.error("Failed to delete user.");
    }
  };

  const resetChatToDisplay = () => {
    dispatch({
      type: "UPDATE_MSG_FROM_SESSION",
      payload: {
        chatMsgsToDisplay: [],
        chatMsgsForServer: [],
      },
    });
  };

  const deleteAllChatHandler = async () => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-chat-history?email=${currentUser.email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Chats  deleted successfully!");
      getSavedChatList();
      setSettingsOpen(false);
      resetChatToDisplay();
    } catch (error) {
      toast.error("Failed to delete chats.");
    }
  };

  const uploadFileHandler = async (selectedFiles) => {
    setUploadFileLoading(true);
    if (!selectedFiles || selectedFiles.length === 0) {
      return;
    }

    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }

    if (currentUser && Object.keys(currentUser).length > 0 && chatId) {
      formData.append("user_email", currentUser.email);
      formData.append("chat_id", chatId);
    }

    try {
      const response = await axios.post(`${getAPIBaseUrl()}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setUploadedFiles(response.data?.files);
      setUploadFileLoading(false);
    } catch (error) {
      console.error("Error uploading files: ", error);
      setUploadFileLoading(false);
    }
  };

  const renderUploadedFile = () => {
    if (!uploadedFiles || uploadedFiles.length === 0) return null;

    return (
      <div
        className={` flex flex-row gap-4 h-18 border-b border-white border-opacity-50 ${
          theme === "dark" ? "bg-black-1100" : "bg-neutral-50"
        } rounded-t-lg w-[100%] overflow-x-auto`}
      >
        {uploadedFiles.map((file, index) => {
          const fileType = file.file_type;
          return (
            <div>
              {fileType.startsWith("image/") ? (
                <div className="relative w-full">
                  <img
                    src={file.file_url}
                    alt="Uploaded"
                    className={`w-12 h-12 border rounded-lg  border-opacity-20 m-4 ${
                      theme === "dark" ? "border-white" : "border-black"
                    }`}
                  />
                  <IoCloseCircle
                    className="absolute top-[-2px] left-[72px] cursor-pointer"
                    color={theme === "dark" ? "white" : "black"}
                  />
                </div>
              ) : (
                <div className="relative">
                  <a
                    href={file.file_url}
                    className={`text-white flex flex-row gap-4  py-2 border  m-3 rounded-lg  border-opacity-40 ${
                      theme === "dark"
                        ? "bg-black-200 border-white"
                        : "bg-white border-black"
                    }`}
                  >
                    <FaFileAlt
                      className="ml-4 w-8 h-8 opacity-80"
                      color={theme === "dark" ? "white" : "black"}
                    />
                    <div>
                      <div
                        className={`text-sm   ${
                          theme === "dark" ? "text-white" : "text-black-100"
                        }`}
                      >
                        {file.file_name && file.file_name.length > 16
                          ? file.file_name.slice(0, 16) + "..."
                          : file.file_name}
                      </div>
                      <div
                        className={`text-xs  opacity-60 ${
                          theme === "dark" ? "text-white" : "text-black-100"
                        }`}
                      >
                        {fileType}
                      </div>
                    </div>
                  </a>
                  <IoCloseCircle
                    className="absolute top-[-2px] right-1 cursor-pointer "
                    color={theme === "dark" ? "white" : "black"}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  };

  console.log("currentUser", currentUser);
  const handleKeyPress = (e) => {
    let convertedValue = inputValue.replace(/\n/g, "\n\n");
    handleUserQuery(convertedValue);
    setInputValue("");
  };

  const handleUserQuery = (content) => {
    if (handleUserQuery.debounced) {
      clearTimeout(handleUserQuery.debounced);
    }

    handleUserQuery.debounced = setTimeout(async () => {
      setIsScrolling(false);

      if (isStreaming) {
        return;
      }

      let payload = { role: "user", content: content };

      if (uploadedFiles && uploadedFiles.length > 0) {
        payload = {
          ...payload,
          uploadedFiles: uploadedFiles,
        };
      }
      await dispatch({
        type: "ADD_TO_CHAT_DISPLAY",
        payload: {
          ...payload,
        },
      });

      await dispatch({
        type: "SET_IS_STREAMING",
        payload: { isStreaming: true },
      });

      await dispatch({
        type: "SET_LOADING",
        payload: { isLoading: true },
      });

      let newChatMsgsForServer = [];

      if (uploadedFiles && uploadedFiles.length > 0) {
        newChatMsgsForServer = [
          ...chatMsgsForServer,
          {
            role: "user",
            content: `${uploadedFiles.map(
              (file) => file.file_text
            )}  ${content}`,
          },
        ];
      } else {
        newChatMsgsForServer = [
          ...chatMsgsForServer,
          {
            role: "user",
            content: content,
          },
        ];
      }

      await dispatch({
        type: "ADD_TO_SERVER",
        payload: {
          role: "user",
          content: content,
        },
      });

      console.log("responseMode", responseMode);
      console.log("responseMode1", newChatMsgsForServer);

      emitToServer("ai_chat", {
        messages: [...newChatMsgsForServer],
        email:
          userType === "individual"
            ? currentUser?.email
            : currentUser?.user?.email,
        userType: userType,
        ent_id: currentUser?.enterprise?.enterprise_id,
        model: selectedAiModel,
        response_mode: responseMode,
      });
      setUploadedFiles([]);
    }, 100);
  };

  console.log("selectedAiModel>>", selectedAiModel);
  const stopStreamingHandler = () => {
    dispatch({
      type: "AI_STREAM_STOPPED",
      payload: {
        role: "assistant",
        content: latestStream.content,
        id: latestStream.id,
      },
    });
  };

  const likeMsgHandler = async (index) => {
    let updatedMsg = {
      ...chatMsgsToDisplay[index],
      isLiked: true,
    };
    await dispatch({
      type: "UPDATE_CHAT_MSG_BY_INDEX",
      payload: {
        index: index,
        msg: updatedMsg,
      },
    });
    saveChatHistory(true, index, "liked");
  };
  const dislikeMsgHandler = (index) => {
    let updatedMsg = {
      ...chatMsgsToDisplay[index],
      isDisliked: true,
    };
    dispatch({
      type: "UPDATE_CHAT_MSG_BY_INDEX",
      payload: {
        index: index,
        msg: updatedMsg,
      },
    });
    saveChatHistory(true, index, "disliked");
  };

  const updateRows = () => {
    const input = inputRef.current;
    if (input) {
      const numberOfLines = input.value.split("\n").length;
      setRows(numberOfLines === 0 ? 1 : numberOfLines);
    }
  };

  const handleChatHistory = (chat) => {
    if (isStreaming) {
      return;
    }
    navigate(`/chat/${chat.chat_id}`);
  };

  const regenerateHandler = async (content) => {
    chatMsgsToDisplay.pop();
    chatMsgsForServer.pop();

    await dispatch({
      type: "UPDATE_MSG_FROM_SESSION",
      payload: {
        chatMsgsToDisplay: chatMsgsToDisplay,
        chatMsgsForServer: chatMsgsForServer,
      },
    });

    if (isStreaming) {
      return;
    }

    await dispatch({
      type: "SET_IS_STREAMING",
      payload: { isStreaming: true },
    });

    await dispatch({
      type: "SET_LOADING",
      payload: { isLoading: true },
    });

    emitToServer("ai_chat", {
      messages: [...chatMsgsForServer],
      email:
        userType === "individual"
          ? currentUser?.email
          : currentUser?.user?.email,
      user_type: userType || "individual",
      ent_id: currentUser?.enterprise?.enterprise_id,
      model: selectedAiModel,
      response_mode: responseMode,
    });
  };

  const deleteChatHistory = async (chatId) => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-chat?chat_id=${chatId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Deleted chat successfully");
      await getSavedChatList();
      await newChatHandler();
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to delete chat history");
    }
  };

  const getFilesByEmail = async () => {
    const email =
      currentUser && Object.keys(currentUser).length > 0
        ? currentUser.email
        : "";

    if (email) {
      try {
        const response = await axios.get(
          `${getAPIBaseUrl()}/get-user-files-by-email?user_email=${email}`
        );

        if (response && response.data && response.data.files) {
          setFiles(response?.data?.files);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleSettingsHandler = () => {
    setSettingsOpen(true);
  };

  const getSavedChatList = async () => {
    try {
      const email =
        userType === "individual"
          ? currentUser?.email
          : currentUser?.user?.email;

      const response = await axios.get(
        `${getAPIBaseUrl()}/list-chat?user_email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setChatHistory(response.data?.chat_history);
    } catch (error) {
      console.log("error", error);
    }
  };
  const saveChatHistory = async (
    customMsg = false,
    index = null,
    type = null
  ) => {
    let newChatId = userChatId;
    if (!userChatId) {
      newChatId = uuidv4();
      storeDispatch(setChatId(newChatId));
    }
    if (isStreaming) {
      return;
    }

    if (chatMsgsToDisplay.length === 0) {
      return;
    }

    const isLoggedIn = currentUser && Object.keys(currentUser).length > 0;

    if (!isLoggedIn) {
      return;
    }

    let newChatMsgsToDisplay = chatMsgsToDisplay;

    if (customMsg) {
      newChatMsgsToDisplay[index] = {
        ...newChatMsgsToDisplay[index],
        isLiked: type === "liked" ? true : false,
        isDisliked: type === "disliked" ? true : false,
      };
    }
    let data = {
      chat_id: newChatId,
      conversations: JSON.stringify({
        chat_msgs_for_server: chatMsgsForServer,
        chat_msgs_to_display: customMsg
          ? newChatMsgsToDisplay
          : chatMsgsToDisplay,
      }),
      user_email:
        currentUser && Object.keys(currentUser).length > 0
          ? userType === "individual"
            ? currentUser.email
            : currentUser?.user?.email
          : "",
    };

    let email = "";
    if (currentUser && Object.keys(currentUser).length > 0) {
      email =
        userType === "individual"
          ? currentUser.email
          : currentUser?.user?.email;
    }

    try {
      const chatDetails = await axios.get(
        `${getAPIBaseUrl()}/get-chat-history-by-id?chat_id=${newChatId}&email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let title = "";
      let createdAt = "";
      if (
        chatDetails &&
        chatDetails.data &&
        chatDetails.data.chat_history &&
        Object.keys(chatDetails.data.chat_history).length > 0 &&
        chatDetails.data.chat_history.title
      ) {
        title = chatDetails.data.chat_history.title;
      } else {
        const response = await axios.post(`${getAPIBaseUrl()}/generate-title`, {
          message: chatMsgsForServer[chatMsgsForServer.length - 1].content,
        });
        title = response.data.title;
      }

      if (
        chatDetails &&
        chatDetails.data &&
        chatDetails.data.chat_history &&
        Object.keys(chatDetails.data.chat_history).length > 0 &&
        chatDetails.data.chat_history.created_at
      ) {
        createdAt = chatDetails.data.chat_history.created_at;
      } else {
        createdAt = new Date().toISOString();
      }

      data = {
        ...data,
        title: title,
        createdAt: createdAt,
      };
      try {
        await axios.post(`${getAPIBaseUrl()}/save-chat-history`, data);
        getSavedChatList();
      } catch (error) {
        console.log("erro>>", error);
      }
    } catch (error) {
      console.log("error>>");
      navigate("/");
    }
  };

  const handleSuggestionClick = async (content) => {
    if (handleSuggestionClick.debounced) {
      clearTimeout(handleSuggestionClick.debounced);
    }

    handleSuggestionClick.debounced = setTimeout(async () => {
      setIsScrolling(false);

      if (isStreaming) {
        return;
      }
      handleUserQuery(content);
    }, 100);
  };

  const newChatHandler = () => {
    if (isStreaming) {
      return;
    }
    const chatId = uuidv4();
    storeDispatch(setChatId(chatId));
    navigate(`/chat/${chatId}`);
  };

  const renameTitleHandler = useCallback(async (chat) => {
    setSelectedChat(chat.chat_id);
    setUpdatedChatTitle(chat.title);

    setTimeout(() => {
      if (chatInputRef.current) {
        chatInputRef.current.focus();
      }
    }, 100);
  }, []);

  const checkPaymentStatus = async () => {
    let data = {
      user_type: userType || "individual",
      email:
        userType === "individual"
          ? currentUser?.email
          : currentUser?.user?.email,
    };

    if (userType === "enterprise") {
      data = {
        ...data,
        enterprise_id: currentUser?.enterprise?.enterprise_id,
      };
    }

    console.log("currentUser");
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/check-user-payment-status`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response && response.data && response.data.is_payment) {
      } else {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    }
  };

  const getAvailableSuggestions = async () => {
    dispatch({
      type: "SET_LOADING_SUGGESTIONS",
      payload: {
        index: chatMsgsToDisplay.length - 1,
        isLoading: true,
      },
    });

    try {
      const data = {
        content: chatMsgsToDisplay[chatMsgsToDisplay.length - 1]["content"],
      };
      const suggestions = await axios.post(
        `${getAPIBaseUrl()}/get-suggestions`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("suggestions>>", suggestions);
      dispatch({
        type: "SET_AI_SUGGESTIONS",
        payload: {
          suggIndex: chatMsgsToDisplay.length - 1,
          suggestions: [...suggestions?.data],
        },
      });
    } catch (error) {
      dispatch({
        type: "SET_AI_SUGGESTIONS",
        payload: {
          suggIndex: chatMsgsToDisplay.length - 1,
          suggestions: [],
        },
      });
    }
  };

  console.log("chatMsgsT", chatMsgsToDisplay);
  const getChatDetailsById = async () => {
    if (!chatId) {
      dispatch({
        type: "UPDATE_MSG_FROM_SESSION",
        payload: {
          chatMsgsToDisplay: [],
          chatMsgsForServer: [],
        },
      });
      return;
    }

    setChatMsgLoading(true);

    let email = "";
    if (currentUser && Object.keys(currentUser).length > 0) {
      email = currentUser.email;
    }
    try {
      const chatDetails = await axios.get(
        `${getAPIBaseUrl()}/get-chat-history-by-id?chat_id=${chatId}&email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (
        chatDetails &&
        chatDetails.data &&
        chatDetails.data.chat_history &&
        Object.keys(chatDetails.data.chat_history).length > 0
      ) {
        await storeDispatch(setChatId(chatDetails.data.chat_history.chat_id));
        const conversations = JSON.parse(
          chatDetails.data.chat_history.conversations
        );

        dispatch({
          type: "RESET_CHAT_DISPLAY",
        });
        dispatch({
          type: "UPDATE_MSG_FROM_SESSION",
          payload: {
            chatMsgsToDisplay: conversations?.chat_msgs_to_display,
            chatMsgsForServer: conversations?.chat_msgs_for_server,
          },
        });
      } else {
        dispatch({
          type: "UPDATE_MSG_FROM_SESSION",
          payload: {
            chatMsgsToDisplay: [],
            chatMsgsForServer: [],
          },
        });
      }
      setChatMsgLoading(false);
    } catch (error) {
      console.log("error", error);
      navigate("/");
      setChatMsgLoading(false);
    }
  };
  const handleResponseMode = (resMode) => {
    dispatch({
      type: "SET_RESPONSE_MODE",
      payload: {
        responseMode: resMode,
      },
    });
  };

  useEffect(() => {
    if (isScrolling) {
      return;
    }
    if (scrollContainerRef.current) {
      console.log(
        "scrollContainerRef.current1",
        scrollContainerRef.current.scrollHeight
      );
      scrollContainerRef.current.scrollTop +=
        scrollContainerRef.current.scrollHeight;
    }
  }, [latestStream, chatMsgsToDisplay]);

  useEffect(() => {
    let interval;

    if (!showLoading) {
      setLoadingText(
        loadingMsgs[Math.floor(Math.random() * loadingMsgs.length)]
      );

      setLoadingDotsDirection("up");
    } else {
      interval = setInterval(() => {
        const numDots = 5;

        let lastNumDotsChars = loadingText.slice(-numDots);

        let periodCount = lastNumDotsChars.split(".").length - 1;

        if (loadingDotsDirection === "up" && periodCount !== numDots) {
          setLoadingText((prevLoadingText) => prevLoadingText + ".");
        } else if (loadingDotsDirection === "down" && periodCount !== 0) {
          setLoadingText((prevLoadingText) => prevLoadingText.slice(0, -1));
        } else if (periodCount === numDots) {
          setLoadingDotsDirection("down");
        } else if (periodCount === 0) {
          setLoadingDotsDirection("up");
        }
      }, 200);
    }

    return () => {
      clearInterval(interval);
    };
  }, [showLoading, loadingText, loadingDotsDirection]);

  useEffect(() => {
    saveChatHistory();
  }, [chatMsgsForServer, isStreaming]);

  useEffect(() => {
    updateRows();
  }, [inputValue]);

  useEffect(() => {
    getChatDetailsById();
  }, [chatId]);

  useEffect(() => {
    const fetchChatList = () => {
      if (currentUser && Object.keys(currentUser).length > 0) {
        getSavedChatList();
      } else {
        setChatHistory([]);
      }
    };

    fetchChatList(); // Call on mount
    getFilesByEmail();

    window.addEventListener("load", fetchChatList);

    return () => {
      window.removeEventListener("load", fetchChatList);
    };
  }, [currentUser]);

  useEffect(() => {
    const getSavedList = async () => {
      setChatHistoryLoading(true);

      const email =
        userType === "individual"
          ? currentUser?.email
          : currentUser?.user?.email;
      try {
        const response = await axios.get(
          `${getAPIBaseUrl()}/list-chat?user_email=${email}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setChatHistory(response.data?.chat_history);
        setChatHistoryLoading(false);
      } catch (error) {
        console.log("error", error);
        setChatHistoryLoading(false);
      }
    };
    getSavedList();
    checkPaymentStatus();
  }, []);

  useEffect(() => {
    if (currentState === "subscription") {
      getUserBillingInfo();
    }
  }, [currentState]);

  useEffect(() => {
    const lastMsg =
      chatMsgsForServer && chatMsgsForServer.length > 0
        ? chatMsgsForServer[chatMsgsForServer.length - 1]["content"]
        : "";

    const availableSuggestions =
      chatMsgsToDisplay[chatMsgsToDisplay.length - 1] &&
      chatMsgsToDisplay[chatMsgsToDisplay.length - 1]["suggestions"]
        ? chatMsgsToDisplay[chatMsgsToDisplay.length - 1]["suggestions"]
        : [];
    if (lastMsg) {
      if (!isStreaming && availableSuggestions.length === 0) {
        getAvailableSuggestions();
      }
    }
  }, [chatMsgsForServer, isStreaming]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log("test", chatInputRef);
      if (
        chatInputRef.current &&
        !chatInputRef.current.contains(event.target)
      ) {
        console.log("test1");

        setSelectedChat("");
        saveUpdatedChatTitle("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedChat]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={CoreStyle.core}>
      <div className="hidden sm:block">
        <Sidebar
          currentPage={currentState}
          setPage={setCurrentState}
          logoutUser={logoutUser}
          userType={userType}
          currentUser={currentUser}
          isChatHistoryExpanded={isChatHistoryExpanded}
          setChatHistoryExpanded={setChatHistoryExpanded}
          isFilesExpanded={isFilesExpanded}
          setFilesExpanded={setFilesExpanded}
          theme={theme}
          files={files}
          chatHistory={chatHistory}
          setShareChatModal={setShareChatModal}
          isShareChatModal={isShareChatModal}
          deleteChatHistory={deleteChatHistory}
          renameTitleHandler={renameTitleHandler}
          handleChatHistory={handleChatHistory}
          chatHistoryLoading={chatHistoryLoading}
          setSelectedChatId={setSelectedChatId}
          saveUpdatedChatTitle={saveUpdatedChatTitle}
          updatedChatTitle={updatedChatTitle}
          chatInputRef={chatInputRef}
          setUpdatedChatTitle={setUpdatedChatTitle}
          expanded={expanded}
          selectedChat={selectedChat}
          newChatHandler={newChatHandler}
        />
      </div>
      <div className={ContentStyle.content}>
        <div className={ContentStyle.bg}>
          <Content
            currentPage={currentState}
            setPage={setCurrentState}
            logoutUser={logoutUser}
            userType={userType}
            currentUser={currentUser}
            theme={theme}
            expanded={expanded}
            billingInfo={billingInfo}
            chatMsgsToDisplay={chatMsgsToDisplay}
            isStreaming={isStreaming}
            uploadedFiles={uploadedFiles}
            latestStream={latestStream}
            dragOver={dragOver}
            setDragOver={setDragOver}
            likeMsgHandler={likeMsgHandler}
            dislikeMsgHandler={dislikeMsgHandler}
            setIsScrolling={setIsScrolling}
            uploadFileHandler={uploadFileHandler}
            inputValue={inputValue}
            setInputValue={setInputValue}
            rows={rows}
            setRows={setRows}
            isUploadFileLoading={isUploadFileLoading}
            handleKeyPress={handleKeyPress}
            renderUploadedFile={renderUploadedFile}
            scrollContainerRef={scrollContainerRef}
            isChatMsgLoading={isChatMsgLoading}
            regenerateHandler={regenerateHandler}
            loadingText={loadingText}
            showLoading={showLoading}
            uploadAudio={uploadAudio}
            isListening={isListening}
            stopStreamingHandler={stopStreamingHandler}
            inputRef={inputRef}
            scrollToBottom={scrollToBottom}
            isFilesExpanded={isFilesExpanded}
            setFilesExpanded={setFilesExpanded}
            files={files}
            isChatHistoryExpanded={isChatHistoryExpanded}
            setChatHistoryExpanded={setChatHistoryExpanded}
            chatHistory={chatHistory}
            chatHistoryLoading={chatHistoryLoading}
            selectedChat={selectedChat}
            chatInputRef={chatInputRef}
            setUpdatedChatTitle={setUpdatedChatTitle}
            saveUpdatedChatTitle={saveUpdatedChatTitle}
            handleChatHistory={handleChatHistory}
            setShareChatModal={setShareChatModal}
            setSelectedChatId={setSelectedChatId}
            renameTitleHandler={renameTitleHandler}
            deleteChatHistory={deleteChatHistory}
            updatedChatTitle={updatedChatTitle}
            newChatHandler={newChatHandler}
            responseMode={responseMode}
            setResponseMode={handleResponseMode}
            selectedAiModel={selectedAiModel}
            setSelectedAiModel={setSelectedAiModel}
            handleSuggestionClick={handleSuggestionClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatScreen;
