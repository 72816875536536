import React, { useEffect, useState } from "react";
import chromeImage from "../../assets/chrome.png";
import safariImage from "../../assets/safari.png";
import { getAPIBaseUrl } from "utility/constants";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { googleLogout } from "@react-oauth/google";
import { setUserDetails } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";

const Session = () => {
  const [sessions, setSessions] = useState([]);
  const { currentUser } = useSelector((state) => state.auth);
  const storeDispatch = useDispatch();
  const navigate = useNavigate()

  const signoutAllDevice = async () => {
    try {
      const data = {
        email: currentUser?.email,
      };
      const response = await axios.post(
        `${getAPIBaseUrl()}/revoke-all-session`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      storeDispatch(setUserDetails({}));
      googleLogout();
      navigate("/login");
    } catch (error) {}
  };

  const fetchSessionDetails = async () => {
    try {
      const data = {
        email: currentUser?.email,
      };
      const response = await axios.post(
        `${getAPIBaseUrl()}/list-session`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data && response.data.sessions) {
        setSessions(response.data.sessions);
      } else {
        setSessions([]);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchSessionDetails();
  }, []);
  return (
    <div>
      <div className=" mb-5 text-2xl font-sans">Your Sessions</div>
      <div className="opacity-50 text-sm mb-8">
        This is a list of devices that have logged into your account. Revoke any
        sessions that you do not recognize.
      </div>
      <div className="pb-3 border-b border-white border-opacity-30 opacity-50">
        Devices
      </div>
      {sessions &&
        sessions.map((session) => (
          <div className="flex flex-row  justify-between py-6">
            <div className="flex flex-row gap-2">
              <div className="bg-black-900 rounded-lg p-2">
                <img src={chromeImage} className="w-8 h-8" />
              </div>

              <div>
                <div className="text-[15px] font-semibold pb-0.5">
                  {session.device_name}
                </div>
                <div className="opacity-70 text-[13px]">
                  {moment(session.last_activity).format("DD MMM YYYY HH:mm a")}
                </div>
              </div>
            </div>
          </div>
        ))}

      <button
        className={`w-full flex justify-center h-12 items-center  bg-blue-50 text-white font-semibold rounded-full shadow-lg hover:from-purple-700 hover:to-indigo-700 transition
 `}
        onClick={signoutAllDevice}
      >
        Sign out all devices
      </button>
    </div>
  );
};

export default Session;
