import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAPIBaseUrl } from "utility/constants";

const ExportChat = () => {
  const { currentUser } = useSelector((state) => state.auth);

  const exportHandler = async () => {
    try {
      const data = {
        user_email: currentUser?.email,
      };
      const response = await axios.post(
        `${getAPIBaseUrl()}/export-chat`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Chat will be sent to your registered email in some time");
    } catch (error) {
      toast.error("Unable to export ! Please try in some time !");
    }
  };

  return (
    <div>
      <div className=" mb-5 text-2xl font-sans">Export Chat</div>

      <div className=" border-opacity-20 flex flex-row justify-between  pb-5  border-white border-opacity-30">
        <div>
          <div className="text-[15px] font-semibold  opacity-90 flex flex-row items-center">
            Export your chat
          </div>
          <div className="opacity-50 text-[13px] mt-2 mr-10">
            {" "}
            Export your chat as a json file ovr your email for easy sharing.
          </div>
        </div>
        <button
          className="border border-white border-opacity-60 rounded-full py-1.5 px-3  h-10"
          onClick={exportHandler}
        >
          Export
        </button>
      </div>
    </div>
  );
};

export default ExportChat;
