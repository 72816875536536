const Input = (props) => {
  return (
    <input
      className={`w-full h-10 mt-2 font-sans text-sm font-medium rounded-xl focus:outline-none    p-3  ${
        props.isError && "border-red-100"
      } bg-white text-black-50 opacity-90 border border-grey-900 focus:border border-white"
      `}
      {...props}
    />
  );
};

export default Input;
