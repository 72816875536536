import axios from "axios";
import { CustomModal } from "components/CustomModal";
import Loader from "components/Loader";
import React, { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoCloseCircle } from "react-icons/io5";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAPIBaseUrl } from "utility/constants";
import PhoneInput from "react-phone-number-input";

const Security = ({ userInfo, getUserInfo }) => {
  const [isMfaModalOpen, setMfaModalOpen] = useState(false);
  const [mfaMethod, setMFaMethod] = useState("");
  const [mfaScreen, setMfaScreen] = useState("select-method");
  const [qrCode, setQrCode] = useState("");
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showDisableMfaModal, setDisableMfaModal] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);

  const { currentUser } = useSelector((state) => state.auth);

  const deleteAllChatHandler = async () => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-chat-history?email=${currentUser.email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Chats  deleted successfully!");
      setShowChatModal(false);
    } catch (error) {
      toast.error("Failed to delete chats.");
    }
  };

  const sendOtpHandler = async () => {
    setOtpLoading(true);
    const data = {
      email: currentUser?.email,
      phoneNumber: phoneNumber,
    };
    try {
      const response = await axios.post(`${getAPIBaseUrl()}/send-otp`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setSuccessMessage("OTP has been sent successfully!");
    } catch (error) {
      toast.error("Something went wrong ! Please try again !");
    } finally {
      setOtpLoading(false);
    }
  };
  const disableMfaHandler = async () => {
    const data = {
      email: currentUser?.email,
      otp: otp,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/disable-mfa`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("MFA is disabled successfully !");
      getUserInfo();
      setDisableMfaModal(false);
    } catch (error) {
      toast.error("Unable to disable MFA! Try agiain");
    }
  };

  const verifyPhoneOtpHandler = async () => {
    setLoading(true);
    const data = {
      email: currentUser?.email,
      otp: otp,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/verify-phone-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setMfaModalOpen(false);
      setMFaMethod("");
      setMfaScreen("select-method");
      setOtp("");
      toast.success("MFA is enabled successfully !");
      getUserInfo();
    } catch (error) {
      toast.error("Invalid code !");
    } finally {
      setLoading(false);
    }
  };

  const verifyOtpHandler = async () => {
    setLoading(true);

    const data = {
      email: currentUser?.email,
      otp: otp,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/verify-authenticator-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setMfaModalOpen(false);
      setQrCode("");
      setMFaMethod("");
      setMfaScreen("select-method");
      setOtp("");
      toast.success("MFA is enabled successfully !");
      getUserInfo();
    } catch (error) {
      toast.error("Invalid code !");
    } finally {
      setLoading(false);
    }
  };
  const enableMfaHandler = async () => {
    if (mfaMethod === "authenticator") {
      const data = {
        email: currentUser?.email,
      };
      try {
        const response = await axios.post(
          `${getAPIBaseUrl()}/generate-qr`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setMfaScreen("verify-otp");
        setQrCode(response?.data?.qr_code);

        console.log("response>>>", response);
      } catch (error) {
        setQrCode("");

        toast.error("Unable top process! Please try again!");
      }
    } else {
      setMfaScreen("verify-otp");
    }
  };

  return (
    <div>
      <div className=" mb-5 text-2xl font-sans">Security</div>

      <div className=" border-opacity-20 flex flex-row justify-between  pb-5 border-b border-white border-opacity-30">
        <div>
          <div className="text-[15px] font-semibold  opacity-90 flex flex-row items-center">
            Multi Factor Authentication
          </div>
          <div className="opacity-50 text-[13px] mt-2">
            {" "}
            Multi-Factor Authentication adds an extra layer of security to your
            account.
          </div>
        </div>
        <button
          className="border border-white border-opacity-60 rounded-full py-1.5 px-3  h-10"
          onClick={(e) => {
            // if (e.target.checked) {
            setMfaModalOpen(true);
            // } else {
            //   setDisableMfaModal(true);
            // }
          }}
        >
          Enable
        </button>
      </div>
      <div className=" border-opacity-20 flex flex-row justify-between py-5">
        <div>
          <div className="text-[15px] font-semibold  opacity-90 flex flex-row items-center">
            Delete All Chats
          </div>
          <div className="opacity-50 text-[13px] mt-2">
            {" "}
            This will permanently delete all your chat history.
          </div>
        </div>
        <button
          className="bg-red-200 rounded-full py-1.5 px-3  h-10"
          onClick={deleteAllChatHandler}
        >
          Delete
        </button>
      </div>
      <CustomModal
        isOpen={isMfaModalOpen}
        handleClose={() => {
          setMfaModalOpen(false);
        }}
      >
        <div className="bg-gray-800">
          <div className="bg-gray-800 p-6 rounded-lg w-fulltext-center shadow-lg mx-4 sm:mx-0">
            <div className="flex flex-row justify-between  mb-4">
              {mfaScreen === "select-method" ? (
                <h2 className="text-xl font-semibold  ">
                  Do you want to enable Multi Factor Authentication
                </h2>
              ) : (
                <div
                  className="flex flex-row gap-2 cursor-pointer"
                  onClick={() => {
                    setMfaScreen("select-method");
                  }}
                >
                  <IoMdArrowRoundBack size={20} className="mt-1" />
                  <div className="mt-1 text-sm">Back</div>
                </div>
              )}
              <div
                className="text-xl font-semibold   cursor-pointer mt-1"
                onClick={() => {
                  setMfaModalOpen(false);
                  setMFaMethod("");
                }}
              >
                <IoCloseCircle />
              </div>
            </div>
            {mfaScreen === "select-method" ? (
              <>
                <p className=" opacity-70 mt-10 mb-2">
                  Please select any one option
                </p>
                <div className="flex flex-row space-x-12">
                  <div className="flex flex-row gap-3 mr-1">
                    <input
                      type="radio"
                      onChange={() => {
                        setMFaMethod("authenticator");
                      }}
                      checked={mfaMethod === "authenticator"}
                      className="w-4 h-4 cursor-pointer mt-1"
                    />
                    <label> Authenticator App</label>
                  </div>
                  <div className="flex flex-row gap-2 mr-1">
                    <input
                      type="radio"
                      onChange={() => {
                        setMFaMethod("phone");
                      }}
                      checked={mfaMethod === "phone"}
                      className="w-4 h-4 cursor-pointer mt-1"
                    />
                    <label> Phone </label>
                  </div>
                </div>
                <button
                  className={`w-full mt-12 flex justify-center items-center h-12 py-3 bg-gradient-to-r from-purple-600 to-indigo-600  font-semibold rounded-full shadow-lg hover:from-purple-700 hover:to-indigo-700 transition
${!mfaMethod ? "opacity-70 cursor-not-allowed" : "cursor-pointer"}`}
                  disabled={!mfaMethod}
                  onClick={enableMfaHandler}
                >
                  Enable MFA
                </button>
              </>
            ) : mfaMethod === "authenticator" ? (
              <>
                {qrCode && (
                  <div>
                    <div className="flex flex-row justify-center mt-4 mb-2">
                      <img
                        src={`data:image/png;base64,${qrCode}`}
                        alt="QR Code"
                        className="w-[160px] h-[160px]"
                      />
                    </div>
                    <p className="text-center">
                      Scan this QR code with your authenticator app.
                    </p>
                  </div>
                )}
                <div className="mt-8">
                  <input
                    type="number"
                    className=" my-3 appearance-none bg-purple-200 relative block w-full p-3  placeholder-gray-500  rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter the code"
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                    value={otp}
                  />
                  <button
                    className={`w-full mt-3 flex justify-center items-center h-12 py-3 bg-gradient-to-r from-purple-600 to-indigo-600  font-semibold rounded-full shadow-lg hover:from-purple-700 hover:to-indigo-700 transition
                    ${
                      !mfaMethod
                        ? "opacity-70 cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    onClick={verifyOtpHandler}
                  >
                    {loading ? <Loader /> : "Verify"}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className=" opacity-70 text-sm">
                  Please enter your phone number
                </div>
                <div className="ph-input-container w-full flex flex-row gap-x-2">
                  <PhoneInput
                    placeholder="Phone number"
                    value={phoneNumber}
                    onChange={(value) => {
                      setPhoneNumber(value);
                    }}
                  />
                  <button
                    className={`w-full  flex justify-center items-center h-10 py-3 bg-gradient-to-r from-purple-600 to-indigo-600  font-semibold rounded-lg mt-1.5 ml-1 shadow-lg hover:from-purple-700 hover:to-indigo-700 transition
                    ${
                      !mfaMethod
                        ? "opacity-70 cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    onClick={sendOtpHandler}
                  >
                    {otpLoading ? <Loader /> : "Send OTP"}
                  </button>
                </div>
                {successMessage && (
                  <div className="text-green-200 text-sm">{successMessage}</div>
                )}

                <div className="mt-8">
                  <div className=" opacity-70 text-sm">
                    Please enter the code send to your phone number
                  </div>
                  <input
                    type="number"
                    className=" my-3 appearance-none bg-purple-200 relative block w-full p-3  placeholder-gray-500  rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter the code"
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                    value={otp}
                  />
                  <button
                    className={`w-full mt-3 flex justify-center items-center h-12 py-3 bg-gradient-to-r from-purple-600 to-indigo-600  font-semibold rounded-full shadow-lg hover:from-purple-700 hover:to-indigo-700 transition
                    ${
                      !mfaMethod
                        ? "opacity-70 cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    onClick={verifyPhoneOtpHandler}
                  >
                    {loading ? <Loader /> : "Verify"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </CustomModal>
      {showChatModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-800 p-6 rounded-lg w-full max-w-sm text-center shadow-lg mx-4 sm:mx-0">
            <h2 className="text-xl font-semibold  mb-4">Confirm Delete</h2>
            <p className="text-gray-300 mb-6">
              Are you sure you want to delete all chat history
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={deleteAllChatHandler}
                className="bg-red-600 hover:bg-red-700  rounded-md font-semibold px-4 py-2"
              >
                Yes, Delete
              </button>
              <button
                onClick={() => setShowChatModal(false)}
                className="bg-gray-600 hover:bg-gray-700  rounded-md font-semibold px-4 py-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Security;
