import Style from "css/modules/designs/LinedBackground.module.css";
import Keyframes from "css/modules/Keyframes.module.css";

function HorizontalLines() {
  return (
    <>
      <div className={Style.hLeft}></div>
      <div className={Style.hMid}></div>
      <div className={Style.hRight}></div>
    </>
  );
}

function DiagonalLines() {
  return (
    <>
      <div className={Style.dLeft}></div>
      <div className={Style.dRight}></div>
    </>
  );
}

function VerticalLines() {
  return (
    <>
      <div className={Style.vTop}></div>
      <div className={Style.vatContainer}>
        <div class="relative w-full h-full">
          <div className={`${Style.vaTop} ${Keyframes.pingPongRight}`}></div>
        </div>
      </div>
      <div className={Style.vabContainer}>
        <div class="relative w-full h-full">
          <div className={`${Style.vaBottom} ${Keyframes.pingPongLeft}`}></div>
        </div>
      </div>
      <div className={Style.vBottom}></div>
    </>
  );
}

function LinedBackground() {
  return (
    <div className={Style.linedBackground}>
      <div className={Style.container}>
        <HorizontalLines />
        <DiagonalLines />
        <VerticalLines />
      </div>
    </div>
  );
}

export default LinedBackground;
