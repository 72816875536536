import { siteName } from "lib/info";
import Style from "css/modules/navigation/Navbar.module.css";
import Button from "css/modules/Button.module.css";

function Navbar({ color = "#00000"}) {
  return (
    <nav className={Style.navbar}>
      <div className={Style.content}>
        <div className={Style.logo}>
          <a href="/redesign"><span class={`font-bold`} style={{ color }}>{siteName}</span></a>
        </div>
        <div className={Style.actions}>
          <a href="#/redesign/login" aria-label="Sign In" className={Button.secondary}>
            Sign In
          </a>
          <a
            href="/redesign/register"
            aria-label="Get Started"
            className={Button.default}
          >
            Get Started
          </a>
        </div>
        <div className={Style.menu}>
          <button aria-label="Menu" className={Button.default}>
            +
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
