import React, { useRef, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import ChatStyle from "./ChatStyle.module.css";
import { BsCreditCard2Back, BsGear } from "react-icons/bs";
import { CgNotes } from "react-icons/cg";
import { FaBars, FaRightFromBracket } from "react-icons/fa6";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import {
  FaQuestionCircle,
  FaRegEdit,
  FaRegQuestionCircle,
  FaUserCircle,
} from "react-icons/fa";
import ChatCard from "../../components/ChatCard";
import useAI from "../../hooks/useAI";
import stopStreamIcon from "../../assets/stop-stream.png";
import { LuSend } from "react-icons/lu";
import { aiModels, helpPrompts, loadingMsgs } from "../../utils";
import { IoIosAttach, IoIosMedkit } from "react-icons/io";
import axios from "axios";
import { getAPIBaseUrl } from "../../utility/constants";
import { FaFileAlt } from "react-icons/fa";
import {
  IoChatboxEllipsesOutline,
  IoChatbubbleEllipsesOutline,
  IoClose,
  IoCloseCircle,
  IoEyeOutline,
} from "react-icons/io5";
import Loader from "../../components/Loader";
import { CustomModal } from "../../components/CustomModal";
import Login from "../auth/Login";
import { setChatId, setUserDetails } from "../../redux/actions/authActions";
import Dropdown from "rc-dropdown";
import logoutIcon from "../../assets/log-out.png";
import hamburgerMenu from "../../assets/Hamburger.png";
import "rc-dropdown/assets/index.css";
import { toast } from "react-toastify";
import { googleLogout } from "@react-oauth/google";
import Signup from "../auth/Signup";
import { v4 as uuidv4 } from "uuid";
import { BiChevronUpCircle, BiDotsHorizontalRounded } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { RiDeleteBin7Line } from "react-icons/ri";
import {
  MdArrowDownward,
  MdChat,
  MdContentCopy,
  MdDriveFileRenameOutline,
  MdOutlineExpandCircleDown,
  MdOutlineHomeRepairService,
  MdOutlinePayment,
  MdOutlinePayments,
  MdPrivacyTip,
} from "react-icons/md";
import Input from "../../components/Input";
import { IoCloseSharp } from "react-icons/io5";
import copy from "copy-to-clipboard";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareReddit } from "react-icons/fa6";
import { FaSquareTwitter } from "react-icons/fa6";
import ChatUiSkeleton from "../../components/ChatUiSkeleton";
import userIcon from "../../assets/user.png";
import Settings from "../../components/Settings";
import { IoMdSettings } from "react-icons/io";
import { IoShareSocial } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineLogout } from "react-icons/ai";
import { useMsal } from "@azure/msal-react";
import { GoDownload, GoMoon, GoQuestion, GoSun } from "react-icons/go";
import ggLogo from "../../assets/gg-new-logo.png";
import { ImFilesEmpty } from "react-icons/im";
import backgroundImage from "../../assets/background.png";
import { MdManageAccounts } from "react-icons/md";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { FaMicrophone, FaStop } from "react-icons/fa"; // Import the icons
import { AudioRecorder } from "react-audio-voice-recorder";
import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarRightCollapse,
  TbShield,
} from "react-icons/tb";
import UserChat from "./UserChat";
import PrivacyPolicy from "../PrivacyPolicy";
import TermsOfService from "../TermsofService";
import UserSettings from "../UserSettings";
import UserBillingInfo from "../UserSettings/UserBillingInfo";
import ggLightLogo from "../../assets/gg-light-logo.png";

const getRandomElements = (arr, numElements = 4) => {
  const shuffledArray = arr.sort(() => 0.5 - Math.random());

  return shuffledArray.slice(0, numElements);
};

const DesktopChatScreen = () => {
  const {
    dispatch,
    chatMsgsToDisplay,
    emitToServer,
    chatMsgsForServer,
    latestStream,
    showLoading,
    isStreaming,
  } = useAI("ai_chat", "ai_chat");
  const { instance, accounts } = useMsal();

  const inputRef = useRef();
  const chatInputRef = useRef();
  const scrollContainerRef = useRef();
  const fileInputRef = useRef();

  const storeDispatch = useDispatch();
  const navigate = useNavigate();

  const { currentUser, userChatId, userType } = useSelector(
    (state) => state.auth
  );

  console.log("");

  console.log("currentUser>>>", currentUser);
  const { chatId } = useParams();

  const [authLoading, setAuthLoading] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [isLoginModal, setLoginModal] = useState(false);
  const [isSignUpModal, setSignUpModal] = useState(false);
  const [signupErrorMessage, setSignupErrorMessage] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [rows, setRows] = useState(1);
  const [isScrolling, setIsScrolling] = useState(false); // To track manual scrolling
  const [loadingText, setLoadingText] = useState(
    loadingMsgs[Math.floor(Math.random() * loadingMsgs.length)]
  );
  const [loadingDotsDirection, setLoadingDotsDirection] = useState("up");
  const [prompts, setPrompts] = useState(getRandomElements(helpPrompts));
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploadFileLoading, setUploadFileLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatHistoryLoading, setChatHistoryLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState("");
  const [updatedChatTitle, setUpdatedChatTitle] = useState("");
  const [isShareChatModal, setShareChatModal] = useState(false);
  const [isCopyLoading, setCopyLoading] = useState(false);
  const [selectedChatTitle, setSelectedChatTitle] = useState("");
  const [expanded, setExpanded] = useState(true);
  const [isChatMsgLoading, setChatMsgLoading] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
  const [files, setFiles] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState("");
  const [isChatHistoryExpanded, setChatHistoryExpanded] = useState(true);
  const [isFilesExpanded, setFilesExpanded] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [isListening, setListening] = useState(false);
  const [audioResponse, setResponseAudio] = useState("");
  const [currentState, setCurrentState] = useState("chat");
  const [billingInfo, setBillingInfo] = useState({});
  const [selectedAiModel, setSelectedAiModel] = useState(["gpt-4o"]);
  const [responseMode, setResponseMode] = useState("sequential");

  document.documentElement.setAttribute("data-theme", theme);
  const language = localStorage.getItem("language") || "english";

  console.log("theme>>", theme);

  const getUserBillingInfo = async () => {
    try {
      const email =
        userType === "individual"
          ? currentUser.email
          : currentUser && currentUser.user && currentUser.user.email
          ? currentUser.user.email
          : "";
      const user_type = userType;
      const enterprise_id =
        userType === "enterprise"
          ? currentUser &&
            currentUser.enterprise &&
            currentUser.enterprise.enterprise_id
            ? currentUser.enterprise.enterprise_id
            : ""
          : "";

      if (email) {
        const response = await axios.get(
          `${getAPIBaseUrl()}/get-billing-info?user_email=${email}&user_type=${user_type}&enterprise_id=${enterprise_id}`
        );

        if (response && response.data) {
          setBillingInfo(response.data);
        }
      } else {
        setBillingInfo({});
      }
    } catch (error) {
      console.error("Error fetching billing info:", error);
      setBillingInfo({});
    } finally {
    }
  };

  const uploadAudio = async (audioBlob) => {
    setListening(true);
    const formData = new FormData();
    formData.append("file", audioBlob, "audio.webm");

    try {
      const response = await fetch(`${getAPIBaseUrl()}/voice-to-text`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();

      if (data && data.transcript) {
        handleUserQuery(data.transcript);

        // const gptResponse = await fetch(`${getAPIBaseUrl()}/chat`, {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({
        //     message: [
        //       {
        //         role: "user",
        //         content: data.transcript,
        //       },
        //     ],
        //   }),
        // });
        // const { response } = await gptResponse.json();

        // const ttsResponse = await fetch(`${getAPIBaseUrl()}/text-to-speech`, {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({ text: response }),
        // });
        // const { audioUrl } = await ttsResponse.json();

        // console.log("audioUrl", audioUrl);
        // setResponseAudio(audioUrl);
        // // Play the audio using the generated URL
        // const audio = new Audio(audioUrl);
        // audio.play();
      }
      console.log("data>>", data);
    } catch (error) {
      console.error("Error uploading audio:", error);
    } finally {
      setListening(false);
    }
  };

  const navigateToSignup = () => {
    setSignUpModal(true);
    setLoginModal(false);
    setLoginErrorMessage("");
    setSignupErrorMessage("");
  };

  const navigateToLogin = () => {
    setSignUpModal(false);
    setLoginModal(true);
    setLoginErrorMessage("");
    setSignupErrorMessage("");
  };

  const saveUpdatedChatTitle = async () => {
    try {
      const response = await axios.put(`${getAPIBaseUrl()}/rename-chat`, {
        chat_id: selectedChat,
        new_title: updatedChatTitle,
      });
      toast.success("Chat title updated successfully");
      setSelectedChat("");
      setUpdatedChatTitle("");
      await getSavedChatList();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSuccess = async (response) => {
    try {
      const token = response.access_token;

      const user_info = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (user_info && Object.keys(user_info).length > 0) {
        const { name, email, given_name, family_name } = user_info.data;

        const data = {
          name: name ? name : given_name + family_name,
          email,
        };
        const response = await axios.post(
          `${getAPIBaseUrl()}/google-login`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        storeDispatch(setUserDetails(response?.data?.user));
        toast.success("Logged in successfully");
        setLoginModal(false);
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Logged in failed ! Please try again !");
    }
  };

  const signupHandler = async (email, password, name) => {
    setAuthLoading(true);

    const data = {
      email,
      password,
      name,
    };
    try {
      const response = await axios.post(`${getAPIBaseUrl()}/signup`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      storeDispatch(setUserDetails(response?.data?.user));
      setAuthLoading(false);
      setSignupErrorMessage("");
      setSignUpModal(false);
      toast.success("Sign up successfully");
    } catch (error) {
      storeDispatch(setUserDetails({}));
      setAuthLoading(false);
      setSignupErrorMessage(error?.response?.data?.error);
      toast.error("Sign up failed ! Try again !");
    }
  };

  const loginHandler = async (email, password) => {
    setAuthLoading(true);

    const data = {
      email,
      password,
    };
    try {
      const response = await axios.post(`${getAPIBaseUrl()}/login`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      storeDispatch(setUserDetails(response?.data?.user));
      setAuthLoading(false);
      setLoginErrorMessage("");
      setLoginModal(false);
      toast.success("Logged in successfully");
    } catch (error) {
      storeDispatch(setUserDetails({}));
      setAuthLoading(false);
      setLoginErrorMessage(error?.response?.data?.error);
      toast.error("Logged in failed ! Try again !");
    }
  };

  const logoutUser = async () => {
    storeDispatch(setUserDetails({}));
    googleLogout();
    resetChatToDisplay();
    navigate("/login");

    const data = {
      email: currentUser?.email,
    };
    const response = await axios.post(
      `${getAPIBaseUrl()}/revoke-all-session`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const deleteUserHandler = async () => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-user?email=${currentUser.email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("User deleted successfully!");
      logoutUser();
      setSettingsOpen(false);
    } catch (error) {
      toast.error("Failed to delete user.");
    }
  };

  const resetChatToDisplay = () => {
    dispatch({
      type: "UPDATE_MSG_FROM_SESSION",
      payload: {
        chatMsgsToDisplay: [],
        chatMsgsForServer: [],
      },
    });
  };

  const deleteAllChatHandler = async () => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-chat-history?email=${currentUser.email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Chats  deleted successfully!");
      getSavedChatList();
      setSettingsOpen(false);
      resetChatToDisplay();
    } catch (error) {
      toast.error("Failed to delete chats.");
    }
  };

  const uploadFileHandler = async (selectedFiles) => {
    setUploadFileLoading(true);
    if (!selectedFiles || selectedFiles.length === 0) {
      return;
    }

    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }

    if (currentUser && Object.keys(currentUser).length > 0 && chatId) {
      formData.append("user_email", currentUser.email);
      formData.append("chat_id", chatId);
    }

    try {
      const response = await axios.post(`${getAPIBaseUrl()}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setUploadedFiles(response.data?.files);
      setUploadFileLoading(false);
    } catch (error) {
      console.error("Error uploading files: ", error);
      setUploadFileLoading(false);
    }
  };

  const renderUploadedFile = () => {
    if (!uploadedFiles || uploadedFiles.length === 0) return null;

    return (
      <div
        className={` flex flex-row gap-4 h-18 border-b border-white border-opacity-50 ${
          theme === "dark" ? "bg-black-1100" : "bg-neutral-50"
        } rounded-t-lg w-[100%] overflow-x-auto`}
      >
        {uploadedFiles.map((file, index) => {
          const fileType = file.file_type;
          return (
            <div>
              {fileType.startsWith("image/") ? (
                <div className="relative w-full">
                  <img
                    src={file.file_url}
                    alt="Uploaded"
                    className={`w-12 h-12 border rounded-lg  border-opacity-20 m-4 ${
                      theme === "dark" ? "border-white" : "border-black"
                    }`}
                  />
                  <IoCloseCircle
                    className="absolute top-[-2px] left-[72px] cursor-pointer"
                    color={theme === "dark" ? "white" : "black"}
                  />
                </div>
              ) : (
                <div className="relative">
                  <a
                    href={file.file_url}
                    className={`text-white flex flex-row gap-4  py-2 border  m-3 rounded-lg  border-opacity-40 ${
                      theme === "dark"
                        ? "bg-black-200 border-white"
                        : "bg-white border-black"
                    }`}
                  >
                    <FaFileAlt
                      className="ml-4 w-8 h-8 opacity-80"
                      color={theme === "dark" ? "white" : "black"}
                    />
                    <div>
                      <div
                        className={`text-sm   ${
                          theme === "dark" ? "text-white" : "text-black-100"
                        }`}
                      >
                        {file.file_name && file.file_name.length > 16
                          ? file.file_name.slice(0, 16) + "..."
                          : file.file_name}
                      </div>
                      <div
                        className={`text-xs  opacity-60 ${
                          theme === "dark" ? "text-white" : "text-black-100"
                        }`}
                      >
                        {fileType}
                      </div>
                    </div>
                  </a>
                  <IoCloseCircle
                    className="absolute top-[-2px] right-1 cursor-pointer "
                    color={theme === "dark" ? "white" : "black"}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  };

  console.log("currentUser", currentUser);
  const handleKeyPress = (e) => {
    let convertedValue = inputValue.replace(/\n/g, "\n\n");
    handleUserQuery(convertedValue);
    setInputValue("");
  };

  const handleUserQuery = (content) => {
    if (handleUserQuery.debounced) {
      clearTimeout(handleUserQuery.debounced);
    }

    handleUserQuery.debounced = setTimeout(async () => {
      setIsScrolling(false);

      if (isStreaming) {
        return;
      }

      let payload = { role: "user", content: content };

      if (uploadedFiles && uploadedFiles.length > 0) {
        payload = {
          ...payload,
          uploadedFiles: uploadedFiles,
        };
      }
      await dispatch({
        type: "ADD_TO_CHAT_DISPLAY",
        payload: {
          ...payload,
        },
      });

      await dispatch({
        type: "SET_IS_STREAMING",
        payload: { isStreaming: true },
      });

      await dispatch({
        type: "SET_LOADING",
        payload: { isLoading: true },
      });

      let newChatMsgsForServer = [];

      if (uploadedFiles && uploadedFiles.length > 0) {
        newChatMsgsForServer = [
          ...chatMsgsForServer,
          {
            role: "user",
            content: `${uploadedFiles.map(
              (file) => file.file_text
            )}  ${content}`,
          },
        ];
      } else {
        newChatMsgsForServer = [
          ...chatMsgsForServer,
          {
            role: "user",
            content: content,
          },
        ];
      }

      await dispatch({
        type: "ADD_TO_SERVER",
        payload: {
          role: "user",
          content: content,
        },
      });

      emitToServer("ai_chat", {
        messages: [...newChatMsgsForServer],
        email:
          userType === "individual"
            ? currentUser?.email
            : currentUser?.user?.email,
        userType: userType,
        ent_id: currentUser?.enterprise?.enterprise_id,
        model: selectedAiModel,
        response_mode: responseMode,
      });
      setUploadedFiles([]);
    }, 100);
  };

  console.log("selectedAiModel>>", selectedAiModel);
  const stopStreamingHandler = () => {
    dispatch({
      type: "AI_STREAM_STOPPED",
      payload: {
        role: "assistant",
        content: latestStream.content,
        id: latestStream.id,
      },
    });
  };

  const likeMsgHandler = async (index) => {
    let updatedMsg = {
      ...chatMsgsToDisplay[index],
      isLiked: true,
    };
    await dispatch({
      type: "UPDATE_CHAT_MSG_BY_INDEX",
      payload: {
        index: index,
        msg: updatedMsg,
      },
    });
    saveChatHistory(true, index, "liked");
  };
  const dislikeMsgHandler = (index) => {
    let updatedMsg = {
      ...chatMsgsToDisplay[index],
      isDisliked: true,
    };
    dispatch({
      type: "UPDATE_CHAT_MSG_BY_INDEX",
      payload: {
        index: index,
        msg: updatedMsg,
      },
    });
    saveChatHistory(true, index, "disliked");
  };

  const updateRows = () => {
    const input = inputRef.current;
    if (input) {
      const numberOfLines = input.value.split("\n").length;
      setRows(numberOfLines === 0 ? 1 : numberOfLines);
    }
  };

  const handleChatHistory = (chat) => {
    if (isStreaming) {
      return;
    }
    navigate(`/chat/${chat.chat_id}`);
  };

  const regenerateHandler = async (content) => {
    chatMsgsToDisplay.pop();
    chatMsgsForServer.pop();

    await dispatch({
      type: "UPDATE_MSG_FROM_SESSION",
      payload: {
        chatMsgsToDisplay: chatMsgsToDisplay,
        chatMsgsForServer: chatMsgsForServer,
      },
    });

    if (isStreaming) {
      return;
    }

    await dispatch({
      type: "SET_IS_STREAMING",
      payload: { isStreaming: true },
    });

    await dispatch({
      type: "SET_LOADING",
      payload: { isLoading: true },
    });

    emitToServer("ai_chat", {
      messages: [...chatMsgsForServer],
      email:
        userType === "individual"
          ? currentUser?.email
          : currentUser?.user?.email,
      user_type: userType || "individual",
      ent_id: currentUser?.enterprise?.enterprise_id,
      model: aiModels.filter((model) => model.label === selectedAiModel)[0]
        .value,
      language: language,
    });
  };

  const deleteChatHistory = async (chatId) => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-chat?chat_id=${chatId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Deleted chat successfully");
      await getSavedChatList();
      await newChatHandler();
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to delete chat history");
    }
  };

  const getFilesByEmail = async () => {
    const email =
      currentUser && Object.keys(currentUser).length > 0
        ? currentUser.email
        : "";

    if (email) {
      try {
        const response = await axios.get(
          `${getAPIBaseUrl()}/get-user-files-by-email?user_email=${email}`
        );

        if (response && response.data && response.data.files) {
          setFiles(response?.data?.files);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleSettingsHandler = () => {
    setSettingsOpen(true);
  };

  const getSavedChatList = async () => {
    try {
      const email =
        userType === "individual"
          ? currentUser?.email
          : currentUser?.user?.email;

      const response = await axios.get(
        `${getAPIBaseUrl()}/list-chat?user_email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setChatHistory(response.data?.chat_history);
    } catch (error) {
      console.log("error", error);
    }
  };
  const saveChatHistory = async (
    customMsg = false,
    index = null,
    type = null
  ) => {
    let newChatId = userChatId;
    if (!userChatId) {
      newChatId = uuidv4();
      storeDispatch(setChatId(newChatId));
    }
    if (isStreaming) {
      return;
    }

    if (chatMsgsToDisplay.length === 0) {
      return;
    }

    const isLoggedIn = currentUser && Object.keys(currentUser).length > 0;

    if (!isLoggedIn) {
      return;
    }

    let newChatMsgsToDisplay = chatMsgsToDisplay;

    if (customMsg) {
      newChatMsgsToDisplay[index] = {
        ...newChatMsgsToDisplay[index],
        isLiked: type === "liked" ? true : false,
        isDisliked: type === "disliked" ? true : false,
      };
    }
    let data = {
      chat_id: newChatId,
      conversations: JSON.stringify({
        chat_msgs_for_server: chatMsgsForServer,
        chat_msgs_to_display: customMsg
          ? newChatMsgsToDisplay
          : chatMsgsToDisplay,
      }),
      user_email:
        currentUser && Object.keys(currentUser).length > 0
          ? userType === "individual"
            ? currentUser.email
            : currentUser?.user?.email
          : "",
    };

    let email = "";
    if (currentUser && Object.keys(currentUser).length > 0) {
      email =
        userType === "individual"
          ? currentUser.email
          : currentUser?.user?.email;
    }

    try {
      const chatDetails = await axios.get(
        `${getAPIBaseUrl()}/get-chat-history-by-id?chat_id=${newChatId}&email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let title = "";
      let createdAt = "";
      if (
        chatDetails &&
        chatDetails.data &&
        chatDetails.data.chat_history &&
        Object.keys(chatDetails.data.chat_history).length > 0 &&
        chatDetails.data.chat_history.title
      ) {
        title = chatDetails.data.chat_history.title;
      } else {
        const response = await axios.post(`${getAPIBaseUrl()}/generate-title`, {
          message: chatMsgsForServer[chatMsgsForServer.length - 1].content,
        });
        title = response.data.title;
      }

      if (
        chatDetails &&
        chatDetails.data &&
        chatDetails.data.chat_history &&
        Object.keys(chatDetails.data.chat_history).length > 0 &&
        chatDetails.data.chat_history.created_at
      ) {
        createdAt = chatDetails.data.chat_history.created_at;
      } else {
        createdAt = new Date().toISOString();
      }

      data = {
        ...data,
        title: title,
        createdAt: createdAt,
      };
      try {
        await axios.post(`${getAPIBaseUrl()}/save-chat-history`, data);
        getSavedChatList();
      } catch (error) {
        console.log("erro>>", error);
      }
    } catch (error) {
      console.log("error>>");
      navigate("/");
    }
  };

  const newChatHandler = () => {
    if (isStreaming) {
      return;
    }
    const chatId = uuidv4();
    storeDispatch(setChatId(chatId));
    navigate(`/chat/${chatId}`);
  };

  const renameTitleHandler = useCallback(async (chat) => {
    setSelectedChat(chat.chat_id);
    setUpdatedChatTitle(chat.title);

    setTimeout(() => {
      if (chatInputRef.current) {
        chatInputRef.current.focus();
      }
    }, 100);
  }, []);

  const checkPaymentStatus = async () => {
    let data = {
      user_type: userType || "individual",
      email:
        userType === "individual"
          ? currentUser?.email
          : currentUser?.user?.email,
    };

    if (userType === "enterprise") {
      data = {
        ...data,
        enterprise_id: currentUser?.enterprise?.enterprise_id,
      };
    }

    console.log("currentUser");
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/check-user-payment-status`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response && response.data && response.data.is_payment) {
      } else {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    }
  };

  const getChatDetailsById = async () => {
    if (!chatId) {
      dispatch({
        type: "UPDATE_MSG_FROM_SESSION",
        payload: {
          chatMsgsToDisplay: [],
          chatMsgsForServer: [],
        },
      });
      return;
    }

    setChatMsgLoading(true);

    let email = "";
    if (currentUser && Object.keys(currentUser).length > 0) {
      email = currentUser.email;
    }
    try {
      const chatDetails = await axios.get(
        `${getAPIBaseUrl()}/get-chat-history-by-id?chat_id=${chatId}&email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (
        chatDetails &&
        chatDetails.data &&
        chatDetails.data.chat_history &&
        Object.keys(chatDetails.data.chat_history).length > 0
      ) {
        await storeDispatch(setChatId(chatDetails.data.chat_history.chat_id));
        const conversations = JSON.parse(
          chatDetails.data.chat_history.conversations
        );

        dispatch({
          type: "RESET_CHAT_DISPLAY",
        });
        dispatch({
          type: "UPDATE_MSG_FROM_SESSION",
          payload: {
            chatMsgsToDisplay: conversations?.chat_msgs_to_display,
            chatMsgsForServer: conversations?.chat_msgs_for_server,
          },
        });
      } else {
        dispatch({
          type: "UPDATE_MSG_FROM_SESSION",
          payload: {
            chatMsgsToDisplay: [],
            chatMsgsForServer: [],
          },
        });
      }
      setChatMsgLoading(false);
    } catch (error) {
      console.log("error", error);
      navigate("/");
      setChatMsgLoading(false);
    }
  };

  useEffect(() => {
    if (isScrolling) {
      return;
    }
    if (scrollContainerRef.current) {
      console.log(
        "scrollContainerRef.current1",
        scrollContainerRef.current.scrollHeight
      );
      scrollContainerRef.current.scrollTop +=
        scrollContainerRef.current.scrollHeight;
    }
  }, [latestStream, chatMsgsToDisplay]);

  useEffect(() => {
    let interval;

    if (!showLoading) {
      setLoadingText(
        loadingMsgs[Math.floor(Math.random() * loadingMsgs.length)]
      );

      setLoadingDotsDirection("up");
    } else {
      interval = setInterval(() => {
        const numDots = 5;

        let lastNumDotsChars = loadingText.slice(-numDots);

        let periodCount = lastNumDotsChars.split(".").length - 1;

        if (loadingDotsDirection === "up" && periodCount !== numDots) {
          setLoadingText((prevLoadingText) => prevLoadingText + ".");
        } else if (loadingDotsDirection === "down" && periodCount !== 0) {
          setLoadingText((prevLoadingText) => prevLoadingText.slice(0, -1));
        } else if (periodCount === numDots) {
          setLoadingDotsDirection("down");
        } else if (periodCount === 0) {
          setLoadingDotsDirection("up");
        }
      }, 200);
    }

    return () => {
      clearInterval(interval);
    };
  }, [showLoading, loadingText, loadingDotsDirection]);

  useEffect(() => {
    saveChatHistory();
  }, [chatMsgsForServer, isStreaming]);

  useEffect(() => {
    updateRows();
  }, [inputValue]);

  useEffect(() => {
    getChatDetailsById();
  }, [chatId]);

  useEffect(() => {
    const fetchChatList = () => {
      if (currentUser && Object.keys(currentUser).length > 0) {
        getSavedChatList();
      } else {
        setChatHistory([]);
      }
    };

    fetchChatList(); // Call on mount
    getFilesByEmail();

    window.addEventListener("load", fetchChatList);

    return () => {
      window.removeEventListener("load", fetchChatList);
    };
  }, [currentUser]);

  useEffect(() => {
    const getSavedList = async () => {
      setChatHistoryLoading(true);

      const email =
        userType === "individual"
          ? currentUser?.email
          : currentUser?.user?.email;
      try {
        const response = await axios.get(
          `${getAPIBaseUrl()}/list-chat?user_email=${email}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setChatHistory(response.data?.chat_history);
        setChatHistoryLoading(false);
      } catch (error) {
        console.log("error", error);
        setChatHistoryLoading(false);
      }
    };
    getSavedList();
    checkPaymentStatus();
  }, []);

  useEffect(() => {
    if (currentState === "subscription") {
      getUserBillingInfo();
    }
  }, [currentState]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log("test", chatInputRef);
      if (
        chatInputRef.current &&
        !chatInputRef.current.contains(event.target)
      ) {
        console.log("test1");

        setSelectedChat("");
        saveUpdatedChatTitle("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedChat]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setExpanded((prevExpanded) => !prevExpanded);
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);

  const sidebarItems = [
    {
      label: "Chat",
      icon: IoChatbubbleEllipsesOutline,
      iconColor: "rgb(0 132 255)",
      state: "chat",
    }, // "chat", "subscription", "settings", "FAQ", "privacy", "termsOfService"
    {
      label: "Subscription",
      icon: BsCreditCard2Back,
      iconColor: "rgb(0 200 13)",
      state: "subscription",
    },
    {
      label: "Updates & FAQ",
      icon: CgNotes,
      iconColor: "rgb(255 60 25)",
      state: "FAQ",
    },
    {
      label: "Settings",
      icon: BsGear,
      iconColor: "rgb(122 132 255)",
      state: "settings",
    },
  ];

  const sidebarFooterItems = [
    {
      label: "Privacy Policy",
      icon: TbShield,
      state: "privacy",
    },
    {
      label: "Terms of Service",
      icon: HiOutlineClipboardDocumentList,
      state: "termsOfService",
    },
  ];

  return (
    <div>
      <div
        className={`${ChatStyle.core} ${expanded ? "" : ChatStyle.collapsed}`}
      >
        <div className={ChatStyle.sidebar}>
          <div class={ChatStyle.sidebarHeader}>
            <div
              className={ChatStyle.menuButton}
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <FaBars size={18} />
            </div>
            <img
              alt="AgentGG Logo"
              className={ChatStyle.logo}
              src={theme === "dark" ? ggLogo : ggLightLogo}
            />
          </div>

          <div className={ChatStyle.sidebarCollection}>
            <div className={ChatStyle.sidebarItems}>
              {sidebarItems.map((item) => (
                <div
                  key={`sidebar-${item.state}`}
                  className={`${ChatStyle.sidebarItem} ${
                    currentState === item.state ? ChatStyle.selected : ""
                  }`}
                  onClick={() => setCurrentState(item.state)}
                >
                  <item.icon size={18} color={item.iconColor || "inherit"} />
                  <div className={ChatStyle.sidebarLabel}>{item.label}</div>
                </div>
              ))}
            </div>
            <div className={ChatStyle.sidebarFooter}>
              {sidebarFooterItems.map((item) => (
                <div
                  key={`sidebar-${item.state}`}
                  className={`${ChatStyle.sidebarItem} ${
                    currentState === item.state ? ChatStyle.selected : ""
                  }`}
                  onClick={() => setCurrentState(item.state)}
                >
                  <item.icon size={22} color={item.iconColor || "inherit"} />
                  <div className={ChatStyle.sidebarLabel}>{item.label}</div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className={ChatStyle.account}>
              <div className={ChatStyle.accountBubble}>
                <FaUserCircle
                  size={46}
                  color={theme === "dark" ? "white" : "black"}
                />
              </div>
              <div className={ChatStyle.accountDetails}>
                <span className={ChatStyle.accountName}>
                  {userType === "individual"
                    ? currentUser &&
                      Object.keys(currentUser).length > 0 &&
                      currentUser.name
                      ? currentUser.name
                      : "NA"
                    : currentUser && currentUser.user && currentUser.user.name
                    ? currentUser.user.name
                    : "NA"}
                </span>
                <span className={ChatStyle.accountEmail}>
                  {" "}
                  {userType === "individual"
                    ? currentUser &&
                      Object.keys(currentUser).length > 0 &&
                      currentUser.email
                      ? currentUser.email
                      : "NA"
                    : currentUser && currentUser.user && currentUser.user.email
                    ? currentUser.user.email
                    : "NA"}
                </span>
              </div>
            </div>
            <div>
              <div className="flex flex-row gap-4">
                <div className="  "></div>
                <div>
                  <div className="text-base"></div>
                  <div className="opacity-70 break-all text-xs"></div>
                </div>
              </div>
              <button
                className={`${ChatStyle.sidebarItem} w-full mt-2`}
                onClick={logoutUser}
              >
                <FaRightFromBracket size={18} />
                <span className={ChatStyle.sidebarLabel}>Logout</span>
              </button>
            </div>

            <div
              className={`${
                ChatStyle.themeContainer
              } rounded-2xl  p-2 text-white flex flex-row gap-4  mt-3
                ${
                  theme === "light"
                    ? "bg-slate-50 text-black-900"
                    : "bg-black-1100 text-white"
                }
                `}
            >
              <div
                className={`${
                  ChatStyle.lightButton
                } flex flex-row gap-2 w-1/2 items-center justify-center cursor-pointer h-full py-1.5 rounded-xl
              ${
                theme === "light"
                  ? "bg-white text-black-900"
                  : "bg-black-900 text-white"
              }
              `}
                onClick={() => {
                  setTheme("light");
                  localStorage.setItem("theme", "light");
                  document.documentElement.setAttribute("data-theme", "light");
                }}
              >
                <GoSun color={theme === "dark" ? "white" : "black"} />
                <div className={ChatStyle.sidebarLabel}>Light</div>
              </div>
              <div
                className={`${
                  ChatStyle.darkButton
                } flex flex-row gap-2 w-1/2 items-center justify-center cursor-pointer  h-full py-1.5 rounded-lg
              ${
                theme === "light"
                  ? "bg-transparent text-black-900"
                  : "bg-black-900 text-white"
              }
              `}
                onClick={() => {
                  setTheme("dark");
                  localStorage.setItem("theme", "dark");
                  document.documentElement.setAttribute("data-theme", "dark");
                }}
              >
                <GoMoon color={theme === "dark" ? "white" : "black"} />
                <div className={ChatStyle.sidebarLabel}>Dark</div>
              </div>
            </div>
          </div>
        </div>
        {currentState === "chat" ? (
          <UserChat
            expanded={expanded}
            isChatMsgLoading={isChatMsgLoading}
            uploadedFiles={uploadedFiles}
            setIsScrolling={setIsScrolling}
            scrollContainerRef={scrollContainerRef}
            chatMsgsToDisplay={chatMsgsToDisplay}
            isStreaming={isStreaming}
            loadingText={loadingText}
            latestStream={latestStream}
            rows={rows}
            setRows={setRows}
            inputValue={inputValue}
            setInputValue={setInputValue}
            regenerateHandler={regenerateHandler}
            likeMsgHandler={likeMsgHandler}
            dislikeMsgHandler={dislikeMsgHandler}
            scrollToBottom={scrollToBottom}
            dragOver={dragOver}
            setDragOver={setDragOver}
            setShareChatModal={setShareChatModal}
            uploadFileHandler={uploadFileHandler}
            renderUploadedFile={renderUploadedFile}
            inputRef={inputRef}
            handleKeyPress={handleKeyPress}
            showLoading={showLoading}
            isUploadFileLoading={isUploadFileLoading}
            stopStreamingHandler={stopStreamingHandler}
            chatHistory={chatHistory}
            updatedChatTitle={updatedChatTitle}
            files={files}
            isFilesExpanded={isFilesExpanded}
            isListening={isListening}
            deleteChatHistory={deleteChatHistory}
            selectedChat={selectedChat}
            chatInputRef={chatInputRef}
            fileInputRef={fileInputRef}
            uploadAudio={uploadAudio}
            setFilesExpanded={setFilesExpanded}
            newChatHandler={newChatHandler}
            isChatHistoryExpanded={isChatHistoryExpanded}
            setChatHistoryExpanded={setChatHistoryExpanded}
            chatHistoryLoading={chatHistoryLoading}
            setUpdatedChatTitle={setUpdatedChatTitle}
            saveUpdatedChatTitle={saveUpdatedChatTitle}
            handleChatHistory={handleChatHistory}
            setSelectedChatId={setSelectedChatId}
            renameTitleHandler={renameTitleHandler}
            theme={theme}
            selectedAiModel={selectedAiModel}
            setSelectedAiModel={setSelectedAiModel}
            responseMode={responseMode}
            setResponseMode={setResponseMode}
          />
        ) : currentState === "privacy" ? (
          <PrivacyPolicy theme={theme} expanded={expanded} />
        ) : currentState === "termsOfService" ? (
          <TermsOfService theme={theme} expanded={expanded} />
        ) : currentState === "settings" ? (
          <CustomModal
            isOpen={currentState === "settings"}
            handleClose={() => {
              setCurrentState("chat");
            }}
          >
            <UserSettings theme={theme} />
          </CustomModal>
        ) : currentState === "subscription" ? (
          <UserBillingInfo
            billingInfo={billingInfo}
            theme={theme}
            expanded={expanded}
          />
        ) : (
          ""
        )}
      </div>
      <CustomModal
        isOpen={isShareChatModal}
        handleClose={() => {
          setShareChatModal(false);
        }}
        theme={theme}
      >
        <div className="flex flex-col px-4 py-4 ">
          <div className="flex flex-row justify-between">
            <div
              className={`${
                theme === "dark" ? "text-white" : "text-black-100"
              } font-bold text-center text-xl`}
            >
              Share Chat
            </div>
            <IoCloseSharp
              color={theme === "dark" ? "white" : "black"}
              size={24}
              className="cursor-pointer"
              onClick={() => {
                setShareChatModal(false);
              }}
            />
          </div>
          <div className="flex flex-row gap-2">
            <div
              className={` p-2 rounded-lg mt-8 ${
                theme === "dark"
                  ? "border border-white"
                  : "border border-black-100"
              }`}
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 1,
                wordBreak: "break-all",
              }}
            >
              {window.location.href}
            </div>
            <div
              className="bg-white text-xl flex flex-row gap-2 text-black-100 w-fit p-2 h-10 mt-8 rounded-lg cursor-pointer"
              onClick={async () => {
                setCopyLoading(true);
                await axios.post(`${getAPIBaseUrl()}/make-chat-public`, {
                  chat_id: selectedChatId,
                });

                copy(window.location.href);
                setCopyLoading(false);
              }}
            >
              {isCopyLoading ? (
                <div className="flex flex-row justify-center w-[60px] h-10">
                  <Loader />
                </div>
              ) : (
                <div className="flex flex-row justify-between w-[60px] h-10">
                  <div className="flex flex-row justify-between mr-1">Copy</div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row gap-6 mt-8 justify-center">
            <a
              className={`${
                theme === "dark" ? "bg-black-200" : "bg-white"
              } p-2.5 rounded-lg cursor-pointer`}
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin size={20} />
            </a>
            <a
              className={`${
                theme === "dark" ? "bg-black-200" : "bg-white"
              } p-2.5 rounded-lg cursor-pointer`}
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookSquare size={20} />
            </a>
            <a
              className={`${
                theme === "dark" ? "bg-black-200" : "bg-white"
              } p-2.5 rounded-lg cursor-pointer`}
              href={`https://twitter.com/intent/tweet?text=${selectedChatTitle}&url=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaSquareTwitter size={20} />
            </a>
            <a
              className={`${
                theme === "dark" ? "bg-black-200" : "bg-white"
              } p-2.5 rounded-lg cursor-pointer`}
              href={`https://www.reddit.com/submit?url=${window.location.href}&title=${selectedChatTitle}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaSquareReddit size={20} />
            </a>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default DesktopChatScreen;
