import React from "react";
import DesktopChatScreen from "./DesktopChatScreen";
import { useMediaQuery } from "react-responsive";
import MobileChatScreen from "./MobileChatScreen";


const ChatScreen = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return <>{isMobile ? <MobileChatScreen /> : <DesktopChatScreen />}</>;
};

export default ChatScreen;