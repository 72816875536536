import Input from "components/Input";
import Dropdown from "rc-dropdown";
import { useState } from "react";
import { BiChevronUpCircle, BiDotsHorizontalRounded } from "react-icons/bi";
import { FaBars, FaFileAlt } from "react-icons/fa";
import { GoDownload } from "react-icons/go";
import { ImFilesEmpty } from "react-icons/im";
import { IoEyeOutline, IoShareSocial } from "react-icons/io5";
import {
  MdDriveFileRenameOutline,
  MdOutlineExpandCircleDown,
} from "react-icons/md";
import { RiDeleteBin7Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import SidebarItems from "./SidebarItems";
import SidebarStyle from "../css/Sidebar.module.css";
import agentGGLogo from "../../../assets/gg-new-logo.png";

const Sidebar = ({
  currentPage,
  setPage,
  logoutUser,
  userType,
  currentUser,
  isChatHistoryExpanded,
  setChatHistoryExpanded,
  isFilesExpanded,
  setFilesExpanded,
  theme,
  files,
  chatHistory,
  setShareChatModal,
  isShareChatModal,
  deleteChatHistory,
  renameTitleHandler,
  handleChatHistory,
  chatHistoryLoading,
  setSelectedChatId,
  saveUpdatedChatTitle,
  updatedChatTitle,
  chatInputRef,
  setUpdatedChatTitle,
  expanded,
  selectedChat,
  newChatHandler,
}) => {
  const [shown, setShown] = useState(true);
  const navigate = useNavigate();

  const chatHistoryComp = (
    <div className="grow overflow-y-auto hide-scrollbar py-4 flex flex-col gap-4">
      <div>
        <div>
          <div
            className={`cursor-pointer flex w-full justify-between font-bold text-white text-opacity-80`}
            onClick={() => {
              setFilesExpanded(!isFilesExpanded);
            }}
          >
            <span>Upload Files</span>
            {isFilesExpanded ? (
              <BiChevronUpCircle size={22} color="white" />
            ) : (
              <MdOutlineExpandCircleDown size={22} color="white" />
            )}
          </div>
          <div className={`text-xs text-opacity-50`}>
            ({files && files.length > 0 ? files.length : 0} files)
          </div>
        </div>
        {isFilesExpanded && (
          <>
            {files && files.length > 0 ? (
              files.map((file) => (
                <div className="flex justify-between items-center">
                  <div
                    className={`cursor-pointer text-white opacity-60 hover:opacity-100 py-3 text-xs w-full overflow-hidden text-ellipsis text-nowrap`}
                    onClick={() => {
                      navigate(`/chat/${file.chat_id}`);
                    }}
                  >
                    {file.file_name}
                  </div>
                  <a href={file.file_url}>
                    <GoDownload size={22} color="white" />
                  </a>
                </div>
              ))
            ) : (
              <div className="flex flex-row justify-left gap-2 mb-4">
                <ImFilesEmpty
                  color="white"
                  className="opacity-70 w-6 h-6 pr-2"
                />
                <div
                  className={`${
                    theme === "dark" ? "text-white" : "text-black-100"
                  } opacity-70 `}
                >
                  No Files Found
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div>
        <div>
          <div>
            <div
              className={`cursor-pointer flex w-full justify-between font-bold text-white text-opacity-80`}
              onClick={() => {
                setChatHistoryExpanded(!isChatHistoryExpanded);
              }}
            >
              <span>Chat History</span>
              {isChatHistoryExpanded ? (
                <BiChevronUpCircle size={22} color="white" />
              ) : (
                <MdOutlineExpandCircleDown size={22} color="white" />
              )}
            </div>
          </div>
          <div className={`text-xs text-opacity-50`}>
            (
            {chatHistory && chatHistory.length > 0
              ? chatHistory.length === 1
                ? "1 chat"
                : `${chatHistory.length} chats`
              : "0 chats"}
            )
          </div>
        </div>
        {isChatHistoryExpanded ? (
          chatHistoryLoading ? (
            <div>
              {[...Array(5)].map((_, index) => (
                <div
                  key={`chat-history-${index}`}
                  className={`flex justify-between items-center transition-[width] ease-in-out`}
                ></div>
              ))}
            </div>
          ) : (
            <div>
              {chatHistory && chatHistory.length > 0 ? (
                <div className="flex flex-col">
                  {chatHistory.map((chat) => (
                    <div>
                      {selectedChat === chat.chat_id ? (
                        <Input
                          type="text"
                          value={updatedChatTitle}
                          ref={chatInputRef}
                          onChange={(e) => setUpdatedChatTitle(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              saveUpdatedChatTitle();
                            }
                          }}
                        />
                      ) : (
                        <div
                          className={`flex flex-row gap-2 justify-between items-center cursor-pointer`}
                          onClick={() => handleChatHistory(chat)}
                        >
                          <div
                            className={`cursor-pointer text-white opacity-60 hover:opacity-100 py-3 text-xs w-full overflow-hidden text-ellipsis text-nowrap`}
                          >
                            {chat.title ? chat.title : "Untitled"}
                          </div>
                          <Dropdown
                            trigger={["click"]}
                            className="text-white"
                            align={{ offset: [-120, 0] }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            overlay={
                              <div className={"rounded-lg  w-[150%] bg-white"}>
                                <div
                                  className={`flex flex-row p-3 cursor-pointer hover:bg-neutral-50 hover:rounded-t-lg`}
                                  onClick={() => {
                                    setShareChatModal(true);
                                    setSelectedChatId(chat.chat_id);
                                  }}
                                >
                                  <IoShareSocial
                                    className="w-4 h-4"
                                    color={"black"}
                                  />

                                  <div
                                    className={`text-xs font-semibold ml-2 font-sans leading-4 spacing text-black`}
                                  >
                                    Share
                                  </div>
                                </div>
                                <div
                                  className={`flex flex-row p-3 cursor-pointer hover:bg-neutral-50`}
                                  onClick={() => renameTitleHandler(chat)}
                                >
                                  <MdDriveFileRenameOutline
                                    className="w-4 h-4 "
                                    color={theme === "dark" ? "white" : "black"}
                                  />
                                  <div
                                    className={`text-xs font-semibold ml-2 font-sans leading-4 spacing`}
                                  >
                                    Rename
                                  </div>
                                </div>

                                <div
                                  className={`flex flex-row p-3 cursor-pointer hover:bg-neutral-50 hover:rounded-b-lg`}
                                  onClick={() => {
                                    deleteChatHistory(chat.chat_id);
                                  }}
                                >
                                  <RiDeleteBin7Line
                                    className="w-4 h-4"
                                    color={theme === "dark" ? "white" : "black"}
                                  />
                                  <div
                                    className={`text-xs font-semibold ml-2 font-sans leading-4 spacing ${
                                      theme == "dark"
                                        ? "text-white"
                                        : "text-black"
                                    }`}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>
                            }
                          >
                            <BiDotsHorizontalRounded
                              color="white"
                              size={22}
                              className="shrink-0"
                            />
                          </Dropdown>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col mb-5">
                  <div
                    className={`${
                      theme === "dark" ? "text-white" : "text-black-100"
                    } text-xs opacity-80`}
                  >
                    No messages. Please start a conversation.
                  </div>
                </div>
              )}
            </div>
          )
        ) : null}
      </div>
    </div>
  );
  return (
    <>
      <div
        className={`${SidebarStyle.sidebar} ${
          shown ? "" : SidebarStyle.collapse
        }`}
      >
        <div className={SidebarStyle.header}>
          <div
            aria-label="Toggle Sidebar"
            role="button"
            className={SidebarStyle.menuButton}
            onClick={() => setShown(!shown)}
          >
            <FaBars size={18} />
          </div>
          <div className={SidebarStyle.headerImg}>
            <img
              alt="AgentGG Logo"
              className={SidebarStyle.logo}
              src={agentGGLogo}
            />
          </div>
        </div>

        <SidebarItems
          currentPage={currentPage}
          setPage={setPage}
          logoutUser={logoutUser}
          userType={userType}
          currentUser={currentUser}
          chatHistoryComp={shown ? chatHistoryComp : null}
          newChatHandler={newChatHandler}
        />
      </div>
    </>
  );
};

export default Sidebar;
