import { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const BUTTON_VARIANTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  OUTLINED: "oultined",
  SECONDARY_OUTLINED: "secondary_outlined",
  TERTIARY: "tertiary",
};

export const Button = forwardRef((props, ref) => {
  const {
    onClick,
    className,
    disabled = false,
    variant = BUTTON_VARIANTS.PRIMARY,
    children,
  } = props;

  const classes = classNames("font-sans text-xs font-semibold flex flex-row items-center justify-center", className, {
    "cursor-pointer": !disabled,
    "bg-purple-100 text-white hover:bg-purple-300 rounded-lg py-3 px-3 lg:px-8 ":
      variant === BUTTON_VARIANTS.PRIMARY && !disabled ,
    "bg-black-300 text-white rounded-lg py-3 px-8 ":
      variant === BUTTON_VARIANTS.SECONDARY,
    "bg-black-400 text-white border border-purple-200 hover:bg-purple-400 rounded-lg py-3 px-3 lg:px-8 ":
      variant === BUTTON_VARIANTS.OUTLINED,
    "bg-primary-500 text-white border border-white rounded-lg py-3 px-8 ":
      variant === BUTTON_VARIANTS.SECONDARY_OUTLINED,
    "bg-black-500 hover:bg-pink-300 text-white py-2.5 px-5":
      variant === BUTTON_VARIANTS.TERTIARY,
    "bg-purple-100 text-white  cursor-not-allowed rounded-lg py-3 px-8  opacity-40":
      variant === BUTTON_VARIANTS.PRIMARY && disabled,
  });

  return (
    <button ref={ref} className={classes} onClick={onClick} disabled={disabled}>
      <div>{children}</div>
    </button>
  );
});

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
  children: PropTypes.node.isRequired,
};
