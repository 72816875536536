import { useRef, useState } from "react";
import SidebarItems from "./SidebarItems";
import { FaBars, FaCaretDown, FaFileAlt } from "react-icons/fa";
import SidebarStyle from "../css/Sidebar.module.css";
import agentGGLogo from "../../../assets/gg-new-logo.png";
import ContentStyle from "../css/Content.module.css";
import ChatStyle from "../css/Chat.module.css";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { IoIosAttach } from "react-icons/io";
import { LuSend } from "react-icons/lu";
import { AiOutlineOpenAI } from "react-icons/ai";
import ChatCard from "components/ChatCard";
import { AudioRecorder } from "react-audio-voice-recorder";
import Loader from "components/Loader";
import ModelPopup from "./ModalPopup";
import ChatUiSkeleton from "components/ChatUiSkeleton";
import stopStreamIcon from "../../../assets/stop-stream.png";
import {
  MdArrowDownward,
  MdDriveFileRenameOutline,
  MdOutlineExpandCircleDown,
} from "react-icons/md";
import { BiChevronUpCircle, BiDotsHorizontalRounded } from "react-icons/bi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { IoEyeOutline, IoShareSocial } from "react-icons/io5";
import { ImFilesEmpty } from "react-icons/im";
import { GoDownload } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import Dropdown from "rc-dropdown";
import Input from "../../../components/Input";
import { FaRepeat } from "react-icons/fa6";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Markdown from "markdown-to-jsx";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Chat = ({
  currentPage,
  setPage,
  logoutUser,
  userType,
  currentUser,
  chatMsgsToDisplay,
  isStreaming,
  uploadedFiles,
  latestStream,
  dragOver,
  setDragOver,
  likeMsgHandler,
  dislikeMsgHandler,
  setIsScrolling,
  uploadFileHandler,
  inputValue,
  setInputValue,
  rows,
  setRows,
  isUploadFileLoading,
  handleKeyPress,
  renderUploadedFile,
  scrollContainerRef,
  isChatMsgLoading,
  regenerateHandler,
  loadingText,
  showLoading,
  uploadAudio,
  isListening,
  stopStreamingHandler,
  inputRef,
  scrollToBottom,
  expanded,
  isFilesExpanded,
  setFilesExpanded,
  files,
  isChatHistoryExpanded,
  setChatHistoryExpanded,
  chatHistory,
  chatHistoryLoading,
  selectedChat,
  chatInputRef,
  setUpdatedChatTitle,
  saveUpdatedChatTitle,
  handleChatHistory,
  setShareChatModal,
  setSelectedChatId,
  renameTitleHandler,
  deleteChatHistory,
  updatedChatTitle,
  newChatHandler,
  responseMode,
  setResponseMode,
  selectedAiModel,
  setSelectedAiModel,
  handleSuggestionClick,
}) => {
  const [sidebarShown, setSidebarShown] = useState(false);
  const [modelPopup, setModelPopup] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const theme = localStorage.getItem("theme") || "dark";
  const fileInputRef = useRef();
  const navigate = useNavigate();

  const chatHistoryComp = (
    <div className="grow overflow-y-auto hide-scrollbar py-4 flex flex-col gap-4">
      <div>
        <div>
          <div
            className={`cursor-pointer flex w-full justify-between font-bold text-white text-opacity-80`}
            onClick={() => {
              setFilesExpanded(!isFilesExpanded);
            }}
          >
            <span>Upload Files</span>
            {isFilesExpanded ? (
              <BiChevronUpCircle size={22} color="white" />
            ) : (
              <MdOutlineExpandCircleDown size={22} color="white" />
            )}
          </div>
          <div className={`text-xs text-opacity-50`}>
            ({files && files.length > 0 ? files.length : 0} files)
          </div>
        </div>
        {isFilesExpanded && (
          <>
            {files && files.length > 0 ? (
              files.map((file) => (
                <div className="flex justify-between items-center">
                  <div
                    className={`cursor-pointer text-white opacity-60 hover:opacity-100 py-3 text-xs w-full overflow-hidden text-ellipsis text-nowrap`}
                    onClick={() => {
                      navigate(`/chat/${file.chat_id}`);
                    }}
                  >
                    {file.file_name}
                  </div>
                  <a href={file.file_url}>
                    <GoDownload size={22} color="white" />
                  </a>
                </div>
              ))
            ) : (
              <div className="flex flex-row justify-left gap-2 mb-4">
                <ImFilesEmpty
                  color="white"
                  className="opacity-70 w-6 h-6 pr-2"
                />
                <div
                  className={`${
                    theme === "dark" ? "text-white" : "text-black-100"
                  } opacity-70 `}
                >
                  No Files Found
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div>
        <div>
          <div>
            <div
              className={`cursor-pointer flex w-full justify-between font-bold text-white text-opacity-80`}
              onClick={() => {
                setChatHistoryExpanded(!isChatHistoryExpanded);
              }}
            >
              <span>Chat History</span>
              {isChatHistoryExpanded ? (
                <BiChevronUpCircle size={22} color="white" />
              ) : (
                <MdOutlineExpandCircleDown size={22} color="white" />
              )}
            </div>
          </div>
          <div className={`text-xs text-opacity-50`}>
            (
            {chatHistory && chatHistory.length > 0
              ? chatHistory.length === 1
                ? "1 chat"
                : `${chatHistory.length} chats`
              : "0 chats"}
            )
          </div>
        </div>
        {isChatHistoryExpanded ? (
          chatHistoryLoading ? (
            <div>
              {[...Array(5)].map((_, index) => (
                <div
                  key={`chat-history-${index}`}
                  className={`flex justify-between items-center transition-[width] ease-in-out`}
                ></div>
              ))}
            </div>
          ) : (
            <div>
              {chatHistory && chatHistory.length > 0 ? (
                <div className="flex flex-col">
                  {chatHistory.map((chat) => (
                    <div>
                      {selectedChat === chat.chat_id ? (
                        <Input
                          type="text"
                          value={updatedChatTitle}
                          ref={chatInputRef}
                          onChange={(e) => setUpdatedChatTitle(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              saveUpdatedChatTitle();
                            }
                          }}
                        />
                      ) : (
                        <div
                          className={`flex flex-row gap-2 justify-between items-center cursor-pointer`}
                          onClick={() => handleChatHistory(chat)}
                        >
                          <div
                            className={`cursor-pointer text-white opacity-60 hover:opacity-100 py-3 text-xs w-full overflow-hidden text-ellipsis text-nowrap`}
                          >
                            {chat.title ? chat.title : "Untitled"}
                          </div>
                          <Dropdown
                            trigger={["click"]}
                            className="text-white"
                            align={{ offset: [-120, 0] }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            overlay={
                              <div className={"rounded-lg  w-[150%] bg-white"}>
                                <div
                                  className={`flex flex-row p-3 cursor-pointer hover:bg-neutral-50 hover:rounded-t-lg`}
                                  onClick={() => {
                                    setShareChatModal(true);
                                    setSelectedChatId(chat.chat_id);
                                  }}
                                >
                                  <IoShareSocial
                                    className="w-4 h-4"
                                    color={"black"}
                                  />

                                  <div
                                    className={`text-xs font-semibold ml-2 font-sans leading-4 spacing text-black`}
                                  >
                                    Share
                                  </div>
                                </div>
                                <div
                                  className={`flex flex-row p-3 cursor-pointer hover:bg-neutral-50`}
                                  onClick={() => renameTitleHandler(chat)}
                                >
                                  <MdDriveFileRenameOutline
                                    className="w-4 h-4 "
                                    color={theme === "dark" ? "white" : "black"}
                                  />
                                  <div
                                    className={`text-xs font-semibold ml-2 font-sans leading-4 spacing`}
                                  >
                                    Rename
                                  </div>
                                </div>

                                <div
                                  className={`flex flex-row p-3 cursor-pointer hover:bg-neutral-50 hover:rounded-b-lg`}
                                  onClick={() => {
                                    deleteChatHistory(chat.chat_id);
                                  }}
                                >
                                  <RiDeleteBin7Line
                                    className="w-4 h-4"
                                    color={theme === "dark" ? "white" : "black"}
                                  />
                                  <div
                                    className={`text-xs font-semibold ml-2 font-sans leading-4 spacing ${
                                      theme == "dark"
                                        ? "text-white"
                                        : "text-black"
                                    }`}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>
                            }
                          >
                            <BiDotsHorizontalRounded
                              color="white"
                              size={22}
                              className="shrink-0"
                            />
                          </Dropdown>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col mb-5">
                  <div
                    className={`${
                      theme === "dark" ? "text-white" : "text-black-100"
                    } text-xs opacity-80`}
                  >
                    No messages. Please start a conversation.
                  </div>
                </div>
              )}
            </div>
          )
        ) : null}
      </div>
    </div>
  );
  return (
    <>
      <div
        aria-label="Close Mobile Sidebar"
        role="button"
        className={`${SidebarStyle.underlay} ${
          sidebarShown ? "" : SidebarStyle.collapse
        }`}
        onClick={() => setSidebarShown(false)}
      ></div>
      <div
        className={`${SidebarStyle.mobileSidebar} ${
          sidebarShown ? "" : SidebarStyle.collapse
        }`}
      >
        <div className={SidebarStyle.header}>
          <div
            aria-label="Toggle Mobile Sidebar"
            role="button"
            className={SidebarStyle.menuButton}
            onClick={() => setSidebarShown(!sidebarShown)}
          >
            <FaBars size={18} />
          </div>
          <div className={SidebarStyle.headerImg}>
            <img
              alt="AgentGG Logo"
              className={SidebarStyle.logo}
              src={agentGGLogo}
            />
          </div>
        </div>
        <SidebarItems
          currentPage={currentPage}
          setPage={setPage}
          logoutUser={logoutUser}
          userType={userType}
          currentUser={currentUser}
          chatHistoryComp={chatHistoryComp}
        />
      </div>
      <div className={ContentStyle.chat}>
        <div className={ContentStyle.header}>
          <div
            aria-label="New Chat"
            role="button"
            className={ContentStyle.headerButton}
            onClick={() => {
              setPage("chat");
            }}
          >
            <HiOutlinePencilAlt size={18} onClick={newChatHandler} />
          </div>
          <div className="relative">
            <div
              className={ContentStyle.modelButton}
              onClick={() => {
                setModelPopup(!modelPopup);
              }}
            >
              <AiOutlineOpenAI size={24} color="#00A67E" />
              <span>
                {selectedAiModel && selectedAiModel.length > 1
                  ? `+ ${selectedAiModel.length} more..`
                  : selectedAiModel[0]}
              </span>
              <FaCaretDown size={18} />
            </div>

            {modelPopup && (
              <ModelPopup
                setOpen={setModelPopup}
                selectedAiModel={selectedAiModel}
                setSelectedAiModel={setSelectedAiModel}
                responseMode={responseMode}
                setResponseMode={setResponseMode}
              />
            )}
          </div>
          <div
            aria-label="Toggle Mobile Sidebar"
            role="button"
            className={`${ContentStyle.headerButton} sm:hidden`}
            onClick={() => setSidebarShown(!sidebarShown)}
          >
            <FaBars size={18} />
          </div>

          <div className="hidden sm:block min-w-[42px]"></div>
        </div>

        <div className={ChatStyle.container}>
          <div className={ChatStyle.chat}>
            <div className={ChatStyle.messages}>
              <div className="w-full col-span-3 mt-2">
                {isChatMsgLoading ? (
                  <ChatUiSkeleton theme={theme} />
                ) : (
                  <div className="mt-8 mr-8 mb-2">
                    <div
                      className={`overflow-y-auto hide-scrollbar relative  ${
                        uploadedFiles && uploadedFiles.length > 0
                          ? "h-[calc(100vh-56px-180px-48px)]"
                          : "h-[calc(100vh-56px-160px-48px)]"
                      } `}
                      onWheel={() => {
                        setIsScrolling(true);
                      }}
                      ref={scrollContainerRef}
                    >
                      {chatMsgsToDisplay && chatMsgsToDisplay.length > 0 ? (
                        chatMsgsToDisplay.map((msg, index) => {
                          let msgArray = [];

                          if (msg.content && msg.content.length > 0) {
                            msgArray = msg.content.split("\breakline");
                          }
                          return (
                            <>
                              {msg.responseMode === "parallel" ? (
                                <>
                                  {msgArray && msgArray.length > 0 && (
                                    <Carousel responsive={responsive}>
                                      {msgArray.map((msg) => (
                                        <div className="h-[60vh] overflow-auto">
                                          <Markdown
                                            options={{
                                              overrides: {
                                                h1: {
                                                  component: "h",
                                                  props: {
                                                    className:
                                                      "text-3xl  mt-4 mb-2",
                                                  },
                                                },
                                                h3: {
                                                  component: "h3",
                                                  props: {
                                                    className:
                                                      "text-xl  mt-4 mb-2",
                                                  },
                                                },
                                                h2: {
                                                  component: "h2",
                                                  props: {
                                                    className:
                                                      "text-2xl  mt-6 mb-3",
                                                  },
                                                },
                                                p: {
                                                  component: "p",
                                                  props: { className: "mb-4" },
                                                },
                                                code: {
                                                  component: "code",
                                                  props: {
                                                    className: ` ${
                                                      theme === "dark"
                                                        ? "bg-transparent text-white"
                                                        : "bg-transparent text-black"
                                                    } text-opacity-70 rounded p-1 my-4`,
                                                  },
                                                },
                                                pre: {
                                                  component: "pre",
                                                  props: {
                                                    className: ` ${
                                                      theme === "dark"
                                                        ? "bg-black-200"
                                                        : "bg-neutral-50"
                                                    } rounded p-2 overflow-x-auto my-4`,
                                                  },
                                                },
                                              },
                                            }}
                                            children={msg}
                                          />
                                        </div>
                                      ))}
                                    </Carousel>
                                  )}
                                </>
                              ) : (
                                <ChatCard
                                  message={msg}
                                  regenerateHandler={regenerateHandler}
                                  likeMsgHandler={() => likeMsgHandler(index)}
                                  dislikeMsgHandler={() =>
                                    dislikeMsgHandler(index)
                                  }
                                  isVisible={
                                    index === chatMsgsToDisplay.length - 1
                                  }
                                  isStreaming={isStreaming}
                                  uploadedFiles={msg.uploadedFiles}
                                  theme={theme}
                                />
                              )}

                              {msg.isLoadingSuggestions ? (
                                <div>
                                  <div className="p-2.5 bg-slate-100 rounded-lg ">
                                    {[...Array(3)].map((_, index) => (
                                      <div
                                        key={index}
                                        className={`bg-slate-300 rounded-md p-2.5 h-11 ${
                                          expanded ? "w-full" : "w-16"
                                        } ${index !== 2 && "mb-1"}`}
                                        style={{
                                          transition: "width 0.5s ease-out",
                                          transitionDelay: `${index * 0.1}s`,
                                        }}
                                      >
                                        <div
                                          className={`w-5 h-5 bg-slate-400 rounded-md p-2.5`}
                                        ></div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <div className=" mb-8">
                                  {msg.suggestions &&
                                  msg.suggestions.length > 0 ? (
                                    <>
                                      <div className="p-2.5 bg-black-600 rounded-lg mb-1">
                                        {msg.suggestions.map((sugg, index) => (
                                          <div
                                            className="flex flex-row p-3 px-2.5 hover:bg-black-200 mb-2  rounded-md cursor-pointer "
                                            onClick={() =>
                                              handleSuggestionClick(sugg)
                                            }
                                          >
                                            <div className="text-white font-sans font-medium text-[15px] opacity-[95%]">
                                              {sugg}
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <div className="text-white  h-full flex flex-row items-end pb-8"></div>
                      )}
                      {showLoading ? (
                        <ChatCard
                          message={{
                            role: "assistant",
                            content: loadingText,
                          }}
                          isVisible={false}
                          isStreaming={isStreaming}
                          theme={theme}
                        />
                      ) : latestStream && latestStream.content ? (
                        <div></div>
                      ) : null}
                    </div>

                    <div className="absolute bottom-[100px] left-[45%] z-50 ">
                      <div
                        className={`rounded-full  p-2 cursor-pointer w-8 h-8 hover:bg-black-50  ${
                          theme === "dark" ? "bg-grey-50" : "bg-neutral-50 "
                        }`}
                        onClick={scrollToBottom}
                      >
                        <MdArrowDownward
                          className="w-4 h-4 "
                          color={theme === "dark" ? "white" : "black"}
                        />
                      </div>
                    </div>
                    <div
                      className={`
                          ${
                            theme === "dark" ? "bg-black-1100" : "bg-neutral-50"
                          } rounded-lg 
                        w-[90%] xl:w-[60%] absolute mr-4  bottom-2 ${
                          dragOver ? "drag-over" : ""
                        }`}
                      onDragOver={(e) => {
                        e.preventDefault();
                        setDragOver(true);
                      }}
                      onDragLeave={(e) => {
                        e.preventDefault();
                        setDragOver(false);
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        setDragOver(false);

                        const files = Array.from(e.dataTransfer.files);
                        if (files.length > 0) {
                          uploadFileHandler(files);
                        }
                      }}
                    >
                      {uploadedFiles && uploadedFiles.length > 0 && (
                        <div>{renderUploadedFile()}</div>
                      )}{" "}
                      <div className="w-full max-w-3xl relative">
                        <form
                          autoComplete="off"
                          action=""
                          onSubmit={(e) => e.preventDefault()}
                          className="w-full"
                        >
                          <textarea
                            ref={inputRef}
                            className={ChatStyle.input}
                            value={inputValue}
                            onChange={(e) => {
                              const value = e.target.value;
                              setInputValue(value);
                            }}
                            onKeyDown={(e) => {
                              if (e.shiftKey && e.key === "Enter") {
                                if (rows < 6) {
                                  setRows(rows + 1);
                                }
                                setInputValue(inputValue);
                              } else if (e.key === "Enter" && inputValue) {
                                e.preventDefault();

                                handleKeyPress();
                                setRows(1);
                              }
                            }}
                            placeholder="Message AgentGG.."
                          />
                          <div className="flex pl-2 justify-between">
                            <div>
                              <div
                                className={`w-6 h-6 text-white cursor-pointer`}
                                onClick={() => {
                                  if (!isUploadFileLoading) {
                                    fileInputRef.current.click();
                                  }
                                }}
                              >
                                {isUploadFileLoading ? (
                                  <Loader className="w-6 h-6" />
                                ) : (
                                  <IoIosAttach
                                    color={theme === "dark" ? "white" : "black"}
                                    size={24}
                                  />
                                )}
                              </div>
                              <input
                                ref={fileInputRef}
                                type="file"
                                multiple={true}
                                onChange={(event) => {
                                  uploadFileHandler(event.target.files);
                                }}
                                style={{ display: "none" }}
                              />
                            </div>

                            <div class={`flex items-center  cursor-pointer`}>
                              {isStreaming ? (
                                <div onClick={stopStreamingHandler}>
                                  <img
                                    alt="Stop Stream"
                                    src={stopStreamIcon}
                                    className=" mr-1 pb-"
                                  />
                                </div>
                              ) : (
                                <div className="flex">
                                  <div className="h-8 mr-2">
                                    {isListening ? (
                                      <Loader />
                                    ) : (
                                      <AudioRecorder
                                        onRecordingComplete={(audioBlob) => {
                                          uploadAudio(audioBlob);
                                        }}
                                        audioTrackConstraints={{
                                          noiseSuppression: true,
                                          echoCancellation: true,
                                        }}
                                      />
                                    )}
                                  </div>

                                  <div
                                    className="rounded-xl bg-blue-50 p-2 cursor-poiner"
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      if (inputValue) {
                                        e.preventDefault();

                                        handleKeyPress();
                                      }
                                    }}
                                  >
                                    <LuSend className="w-4 h-4 text-white" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
