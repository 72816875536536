import axios from "axios";
import Loader from "components/Loader";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAPIBaseUrl } from "utility/constants";

const UpdatePassword = () => {
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { currentUser } = useSelector((state) => state.auth);

  const updatePasswordHandler = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords donot match");
      setLoading(false);
      return;
    }
    setLoading(true);
    setErrorMessage(false);

    const data = {
      email: currentUser?.email,
      newPassword: newPassword,
      oldPassword: oldPassword,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/update-password`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Password Updated Successfully !");
      setLoading(false);
    } catch (error) {
      console.log("error.>>", error);
      setErrorMessage(error?.response?.data?.error);
      toast.error("Please try again!");
      setLoading(false);
    }
  };
  return (
    <>
      <div className=" mb-5 text-2xl">Update Password</div>
      <div className="mb-6">
        <div className="text-[13px] pb-1.5 font-sans font-semibold">
          Old Password
        </div>
        <input
          name="password"
          type="password"
          autoComplete="current-password"
          required
          className="appearance-none bg-black-900 block w-full p-3  placeholder-gray-500 text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder="Old Password"
          onChange={(e) => {
            setOldPassword(e.target.value);
          }}
          value={oldPassword}
        />
      </div>
      <div className="mb-8">
        <div className="text-[13px] pb-1.5 font-sans font-semibold">
          New Password
        </div>
        <input
          name="password"
          type="password"
          autoComplete="current-password"
          required
          className="appearance-none bg-black-900 block w-full p-3 mb-1 placeholder-gray-500 text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder="New Password"
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
          value={newPassword}
        />
        <div className="text-white text-opacity-70 text-[10px]">
          Password must be atleast 12 characters , contains uppercase ,
          lowercase letter , digit & special character{" "}
        </div>
      </div>
      <div>
        <div className="text-[13px] pb-1.5 font-sans font-semibold">
          Confirm New Password
        </div>
        <input
          name="password"
          type="password"
          autoComplete="current-password"
          required
          className="appearance-none bg-black-900 block w-full p-3 mb-1 placeholder-gray-500 text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder="Confirm Password"
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          value={confirmPassword}
        />
      </div>
      {errorMessage && (
        <span className="text-red-100 text-sm flex flex-row justify-center pb-1">
          {errorMessage}
        </span>
      )}
      <button
        className={`w-full mt-8 flex justify-center h-12 items-center  bg-blue-50 text-white  rounded-full shadow-lg hover:from-purple-700 hover:to-indigo-700 transition
`}
        onClick={updatePasswordHandler}
      >
        {loading ? <Loader /> : "Update Password"}
      </button>
    </>
  );
};

export default UpdatePassword;
