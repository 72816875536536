import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import axios from "axios";
import { getAPIBaseUrl } from "../../utility/constants";

const VerifyIdentity = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");

  const { currentUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const sendOtpHandler = async () => {
    setOtpLoading(true)
    const data = {
      email: currentUser?.email,
      phoneNumber: currentUser?.phone_number,
    };
    try {
      const response = await axios.post(`${getAPIBaseUrl()}/send-otp`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setSuccessMessage(
        `OTP has been sent successfully to ${currentUser.phone_number}`
      );
    } catch (error) {
      toast.error("Something went wrong! Please try again !");
    }
    finally{
        setOtpLoading(false)

    }
  };

  const handleSubmit = async (e) => {
    if (currentUser.mfa_method === "authenticator") {
      setLoading(true);

      const data = {
        email: currentUser?.email,
        otp: otp,
      };
      try {
        const response = await axios.post(
          `${getAPIBaseUrl()}/verify-authenticator-otp`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setOtp("");
        toast.success("User verified successfully");
        navigate("/");
      } catch (error) {
        toast.error("Invalid code ! Try again !");
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(true);
      const data = {
        email: currentUser?.email,
        otp: otp,
      };
      try {
        const response = await axios.post(
          `${getAPIBaseUrl()}/verify-phone-otp`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setOtp("");
        toast.success("User verified successfully");
        navigate("/");
      } catch (error) {
        toast.error("Invalid code ! Try again !");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
      <div className="bg-gray-800 shadow-lg rounded-lg p-8 max-w-md w-full">
        <div className="text-center mb-6">
          <h1 className="text-2xl font-semibold">Verify Your Identity</h1>
          {currentUser.mfa_method === "authenticator" ? (
            <p className="text-sm text-gray-400 mt-2">
              Check your preferred one-time password application for a code.
            </p>
          ) : (
            <p className="text-sm text-gray-400 mt-2">
              Check your registered phone for one time code.
            </p>
          )}
        </div>

        <div className="space-y-6">
          <div>
            <label
              htmlFor="otp"
              className="block text-sm font-medium text-gray-400 mb-2"
            >
              Enter your one-time code
            </label>
            {currentUser.mfa_method === "phone" ? (
              <>
                <div className="flex flex-row gap-4">
                  <input
                    type="text"
                    id="otp"
                    value={otp}
                    onChange={handleOtpChange}
                    placeholder="Enter your OTP"
                    className="block w-full bg-gray-700 text-white border border-gray-600 rounded-md p-3 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                  <button
                    className={`w-[25%] flex justify-center h-12 items-center  bg-violet-300 text-xs text-white font-semibold rounded-lg shadow-lg hover:from-purple-700 hover:to-indigo-700 transition
 `}
                    onClick={sendOtpHandler}
                  >
                    {
                        otpLoading ? <Loader /> : "Send OTP"
                    }
                  </button>
                </div>
                {successMessage && (
                  <div className="text-green-200 text-sm mt-1">{successMessage}</div>
                )}
              </>
            ) : (
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={handleOtpChange}
                placeholder="Enter your OTP"
                className="block w-full bg-gray-700 text-white border border-gray-600 rounded-md p-3 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              />
            )}
          </div>

          <button
            onClick={handleSubmit}
            className={`w-full flex justify-center h-12 items-center  bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-semibold rounded-full shadow-lg hover:from-purple-700 hover:to-indigo-700 transition
                ${
                  loading ? "opacity-70 cursor-not-allowed" : "cursor-pointer"
                }`}
          >
            {loading ? <Loader /> : "Verify"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyIdentity;
