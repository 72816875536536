// CSS
import "./App.css";

// Pages
import Home from "pages/redesign/Home";
import Register from "pages/redesign/Register";

// Unorganized imports
import { useRoutes } from "react-router-dom";
import ChatScreen from "./pages/ChatScreen";
import ChatScreenOld from "./pages/ChatScreen/index_old";
import { configureStore } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import MSLoginRedirect from "./pages/auth/MSLoginRedirect";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsofService";
import Login from "./pages/auth/Login";
import ResetPassword from "./pages/auth/ResetPassword";
import Signup from "./pages/auth/Signup";
import Billing from "./pages/Payment/Billing";
import SuccessPage from "./pages/Payment/SuccessPage";
import UserSettings from "./pages/UserSettings";
import EnterpriseContact from "./pages/EnterpiseContact";
import EnterpriseDashboard from "./pages/EnterpriseDashboard"
import VerifyMFA from "./pages/VerifyMFA"
import UserPlans from "./pages/Payment/UserPlans";

const googleClientId =
  "454713927448-fpd2ptmgdkl2uhsdfduats3lc78gofkb.apps.googleusercontent.com";

const AppRoutes = () => {
  const routes = useRoutes([
    { path: "/redesign", element: <Home /> },
    { path: "/redesign/register", element: <Register /> },

    { path: "/login", element: <Login /> },
    { path: "/create-account", element: <Signup /> },
    { path: "/plans", element: <UserPlans /> },
    { path: "/billing", element: <Billing />},
    { path: "/", element: <ChatScreen /> },
    { path: "/old", element: <ChatScreenOld /> },
    { path: "/chat/:chatId", element: <ChatScreen /> },
    { path: "/auth-callback", element: <MSLoginRedirect /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/terms-of-service", element: <TermsOfService /> },
    { path: "/success", element: <SuccessPage /> },
    { path: "/settings", element: <UserSettings /> },
    { path: "/contact-us", element: <EnterpriseContact /> },
    { path: "/reset-password", element: <ResetPassword /> },
    { path: "/enterprise/:enterpriseId", element: <EnterpriseDashboard /> },
    { path: "/verify-mfa", element: <VerifyMFA /> },
  ]);
  return routes;
};

const { store, persistor } = configureStore();

function App() {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AppRoutes />
        </PersistGate>
      </Provider>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </GoogleOAuthProvider>
  );
}

export default App;
