import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createBrowserHistory } from "history";
import rootReducer from "../redux/reducers";

const history = createBrowserHistory();

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export function configureStore(initialState) {
  const store = createStore(
    persistedReducer,
    initialState,
  );
  let persistor = persistStore(store, null, () => {});

  return { store, persistor };
}
