import axios from "axios";
import Loader from "components/Loader";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setUserDetails } from "../../redux/actions/authActions";
import { getAPIBaseUrl } from "utility/constants";

const General = ({ theme, addTokenHandler }) => {
  const { currentUser, userType } = useSelector((state) => state.auth);
  const [selectedAddon, setSelectedAddon] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const usagePercentage = (
    (Number(userInfo?.tokens_used || 0) / Number(userInfo?.tokens_limit || 1)) *
    100
  ).toFixed(2);

  const getUserInfo = async () => {
    try {
      const email = currentUser && currentUser.email ? currentUser.email : "";
      const user_type = userType;
      const enterprise_id =
        currentUser && currentUser.enterprise_id
          ? currentUser.enterprise_id
          : "";

      if (email) {
        const response = await axios.get(
          `${getAPIBaseUrl()}/get-user-info?user_email=${email}&user_type=${user_type}&enterprise_id=${enterprise_id}`
        );

        if (response && response.data && response.data.user_info) {
          setUserInfo(response.data.user_info);
        }
        setPhoneNumber(response.data.user_info?.phone_number);
        setName(response.data.user_info?.name);
      } else {
        setUserInfo({});
      }
    } catch (error) {
      console.error("Error fetching billing info:", error);
      setUserInfo({});
    } finally {
    }
  };

  const updateUserDetails = async () => {
    const data = {
      name,
      phoneNumber,
      email: currentUser?.email,
    };

    setLoading(true);

    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/update-user-details`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Details Updated Successfully !");
      setLoading(false);
      setUserDetails({
        ...currentUser,
        name: name,
      });
      getUserInfo();
    } catch (error) {
      toast.error("Please try again!");
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div>
      <div className=" mb-5 text-2xl font-sans">General</div>
      <div className="mb-5">
        <div className=" mb-2 text-[15px] font-sans">Name</div>
        <input
          className="appearance-none bg-black-900 block w-full p-3 mb-1 placeholder-gray-500 text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="mb-5">
        <div className=" mb-2 text-[15px] font-sans">Email</div>
        <input
          className="appearance-none bg-black-900 block w-full p-3 mb-1 placeholder-gray-500 text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={currentUser.email}
          disabled={true}
        />
      </div>
      <div className="mb-5">
        <div className=" mb-2 text-[15px] font-sans">Phone Number</div>
        <input
          className="appearance-none bg-black-900 block w-full p-3 mb-1 placeholder-gray-500 text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
        />
      </div>
      <div className="w-ful flex flex-row justify-end">
        <button
          className={`w-32 h-8  bg-blue-50 text-white font-semibold rounded-2xl shadow-lg hover:brightness-125
                 
                  `}
          onClick={updateUserDetails}
        >
          {loading ? <Loader /> : "Update"}
        </button>
      </div>
      <div className="">
        <div className=" mb-2 text-[15px] font-sans">GG Tokens Usage </div>
        <div
          className={`relative w-full h-6  rounded-full overflow-hidden
                    ${theme === "light" ? "bg-black-900" : "bg-slate-50"}
                    `}
        >
          <div
            className="absolute top-0 left-0 h-full bg-blue-50 transition-all"
            style={{
              width: `${
                (Number(userInfo?.tokens_used || 0) /
                  Number(userInfo?.tokens_limit || 1)) *
                100
              }%`,
            }}
          ></div>
        </div>
        <div className="text-sm  mt-2 text-center opacity-60">
          {userInfo?.tokens_used} / {userInfo?.tokens_limit} Tokens Used (
          {usagePercentage}
          %)
        </div>
        <div className="mt-5">
          {usagePercentage > 75 && (
            <div className="flex flex-row gap-3">
              <div
                className={`border border-opacity-80 w-fit px-2 py-1 opacity-70 rounded-lg cursor-pointer 
              ${
                selectedAddon === "1M" &&
                "border-blue-50 font-semibold text-blue-50"
              }
              `}
                onClick={() => {
                  setSelectedAddon("1M");
                }}
              >
                1M token for $10
              </div>
              <div
                className={`border border-opacity-80 w-fit px-2 py-1 opacity-70 rounded-lg cursor-pointer 
              ${
                selectedAddon === "3M" &&
                "border-blue-50 font-semibold text-blue-50"
              }
              `}
                onClick={() => {
                  setSelectedAddon("3M");
                }}
              >
                3M token for $30
              </div>

              <button
                className={`w-32 h-8 bg-blue-50 text-white font-semibold rounded-2xl shadow-lg hover:brightness-125
                  ${
                    selectedAddon
                      ? "cursor-pointer"
                      : "opacity-60 cursor-not-allowed"
                  }
                  `}
                onClick={() => [addTokenHandler(selectedAddon)]}
                disabled={!selectedAddon}
              >
                Add tokens
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default General;
