import { CustomModal } from "components/CustomModal";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfService from "pages/TermsofService";
import UserSettings from "pages/UserSettings";
import UserBillingInfo from "pages/UserSettings/UserBillingInfo";
import Chat from "./Chat";

const Content = ({
  currentPage,
  setPage,
  logoutUser,
  userType,
  currentUser,
  theme,
  expanded,
  billingInfo,
  chatMsgsToDisplay,
  isStreaming,
  uploadedFiles,
  latestStream,
  dragOver,
  setDragOver,
  likeMsgHandler,
  dislikeMsgHandler,
  setIsScrolling,
  uploadFileHandler,
  inputValue,
  setInputValue,
  rows,
  setRows,
  isUploadFileLoading,
  handleKeyPress,
  renderUploadedFile,
  scrollContainerRef,
  isChatMsgLoading,
  regenerateHandler,
  loadingText,
  showLoading,
  uploadAudio,
  isListening,
  stopStreamingHandler,
  inputRef,
  scrollToBottom,
  isFilesExpanded,
  setFilesExpanded,
  files,
  isChatHistoryExpanded,
  setChatHistoryExpanded,
  chatHistory,
  chatHistoryLoading,
  selectedChat,
  chatInputRef,
  setUpdatedChatTitle,
  saveUpdatedChatTitle,
  handleChatHistory,
  setShareChatModal,
  setSelectedChatId,
  renameTitleHandler,
  deleteChatHistory,
  updatedChatTitle,
  newChatHandler,
  responseMode,
  setResponseMode,
  selectedAiModel,
  setSelectedAiModel,
  handleSuggestionClick
}) => {
  switch (currentPage || "chat") {
    case "chat":
      return (
        <Chat
          currentPage={currentPage}
          setPage={setPage}
          logoutUser={logoutUser}
          userType={userType}
          currentUser={currentUser}
          chatMsgsToDisplay={chatMsgsToDisplay}
          isStreaming={isStreaming}
          uploadedFiles={uploadedFiles}
          latestStream={latestStream}
          dragOver={dragOver}
          setDragOver={setDragOver}
          likeMsgHandler={likeMsgHandler}
          dislikeMsgHandler={dislikeMsgHandler}
          setIsScrolling={setIsScrolling}
          uploadFileHandler={uploadFileHandler}
          inputValue={inputValue}
          setInputValue={setInputValue}
          rows={rows}
          setRows={setRows}
          isUploadFileLoading={isUploadFileLoading}
          handleKeyPress={handleKeyPress}
          renderUploadedFile={renderUploadedFile}
          scrollContainerRef={scrollContainerRef}
          isChatMsgLoading={isChatMsgLoading}
          regenerateHandler={regenerateHandler}
          loadingText={loadingText}
          showLoading={showLoading}
          uploadAudio={uploadAudio}
          isListening={isListening}
          stopStreamingHandler={stopStreamingHandler}
          inputRef={inputRef}
          scrollToBottom={scrollToBottom}
          expanded={expanded}
          isFilesExpanded={isFilesExpanded}
          setFilesExpanded={setFilesExpanded}
          files={files}
          isChatHistoryExpanded={isChatHistoryExpanded}
          setChatHistoryExpanded={setChatHistoryExpanded}
          chatHistory={chatHistory}
          chatHistoryLoading={chatHistoryLoading}
          selectedChat={selectedChat}
          chatInputRef={chatInputRef}
          setUpdatedChatTitle={setUpdatedChatTitle}
          saveUpdatedChatTitle={saveUpdatedChatTitle}
          handleChatHistory={handleChatHistory}
          setShareChatModal={setShareChatModal}
          setSelectedChatId={setSelectedChatId}
          renameTitleHandler={renameTitleHandler}
          deleteChatHistory={deleteChatHistory}
          updatedChatTitle={updatedChatTitle}
          newChatHandler={newChatHandler}
          responseMode={responseMode}
          setResponseMode={setResponseMode}
          selectedAiModel={selectedAiModel}
          setSelectedAiModel={setSelectedAiModel}
          handleSuggestionClick={handleSuggestionClick}
        />

      );

    case "privacy":
      return <PrivacyPolicy theme={theme} expanded={expanded} setPage={setPage} />;
    case "termsOfService":
      return <TermsOfService theme={theme} expanded={expanded} setPage={setPage} />;
    case "settings":
      return (
        <CustomModal
          isOpen={currentPage === "settings"}
          handleClose={() => {
            setPage("chat");
          }}
        >
          <UserSettings theme={theme} setPage={setPage} />
        </CustomModal>
      );
    case "subscription":
      return (
        <UserBillingInfo
          billingInfo={billingInfo}
          theme={theme}
          expanded={expanded}
        />
      );

    default:
      return <></>;
  }
};

export default Content;
