import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import "tailwindcss/tailwind.css"; // Ensure Tailwind CSS is installed
import { getAPIBaseUrl } from "../../utility/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { companySizes, industryOptions } from "../../utils";
import Loader from "../../components/Loader";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { loadStripe } from "@stripe/stripe-js";

// const stripePromise = loadStripe(
//   "pk_test_51Q0ojDHG7saj5CIN9MTIVlUg8CT7OnuOvRNN7Deo1i7uOLbVlr2HTDZoyCsPxfv7mLlK4ko6dKiB1wRYr3cnL1yF00px5khdl9"
// );


const stripePromise = loadStripe(
  "pk_live_51Q0ojDHG7saj5CINiX3EaCv8ORQTIb9nyOaQvmQ3R0vs7LkuuQtqfH13vzlFsim7Gk0yAiJMbb4CK8GtHDPSlJ0300fE1xnbYY"
);

const EnterpriseContact = () => {
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [addressState, setAddressState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [noOfUsers, setNoOfUsers] = useState("");
  const [loading, setLoading] = useState(false);

  const { currentUser } = useSelector((state) => state.auth);

  const enterpriseSignupHandler = async () => {
    setLoading(true);
    const data = {
      user_email: currentUser?.email,
      enterprise_name: companyName,
      company_address: companyAddress,
      state: addressState,
      zip_code: zipCode,
      ent_contact_number: phoneNumber,
      users_count: noOfUsers,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/create-enterprise-account`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response>>", response)

      let requestData = {
        email: currentUser.email,
        noOfUsers: parseInt(noOfUsers),
        enterprise_id: response?.data?.user?.enterprise_id,
        name: currentUser?.name,
      };

      try {
        const { data } = await axios.post(
          `${getAPIBaseUrl()}/create-enterprise-checkout-session`,
          requestData
        );

        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: data.id,
        });

        if (result.error) {
          console.error(result.error.message);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to initiate checkout");
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.log("error>>", error);
    } finally {
      // setSuccessMessage(
      //   "Thank You ! Your form has been submitted successfully! "
      // );
    }
  };

  return (
    <div className="min-h-screen bg-black-1100">
      <div className="min-h-screen grid grid-cols-4">
        <div></div>
        <div className="h-[97vh] bg-white my-5 rounded-2xl mr-5 overflow-y-auto col-span-2">
          <div className="pb-5">
            <div className="rounded-md shadow-sm mt-5">
              <div className="text-center  text-3xl text-black-1100 font-semibold">
                Enterprise Details
              </div>
              <div className="opacity-70 text-center text-sm">
                Please fill out the following details
              </div>

              <div className="mt-6">
                <div className="mx-4">
                  <div className="my-6">
                    <div className="">
                      <label htmlFor="email-address" className="sr-only">
                        Company Name
                      </label>
                      <input
                        name="companyName"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Company / Organization Name"
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        value={companyName}
                      />
                    </div>
                  </div>

                  <div className="mt-6 mb-1">
                    <label htmlFor="email-address" className="sr-only">
                      Company Address (Line 1)
                    </label>
                    <input
                      name="companyAddress"
                      type="text"
                      required
                      className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Company Address (Line 1)"
                      onChange={(e) => {
                        setCompanyAddress(e.target.value);
                      }}
                      value={companyAddress}
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-6">
                    <div className="">
                      <label htmlFor="email-address" className="sr-only">
                        State
                      </label>
                      <input
                        name="city"
                        type="text"
                        required
                        className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="State"
                        onChange={(e) => {
                          setAddressState(e.target.value);
                        }}
                        value={addressState}
                      />
                    </div>
                    <div className="">
                      <label htmlFor="email-address" className="sr-only">
                        Zip Code
                      </label>
                      <input
                        name="zipCode"
                        type="text"
                        required
                        className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Zip Code"
                        onChange={(e) => {
                          setZipCode(e.target.value);
                        }}
                        value={zipCode}
                      />
                    </div>
                  </div>
                  <div className=" w-full">
                    <label htmlFor="email-address" className="sr-only">
                      Company Contact Number
                    </label>
                    <div className="phone-input-container w-full">
                      <PhoneInput
                        placeholder="Company Contact Number"
                        value={phoneNumber}
                        onChange={(value) => {
                          setPhoneNumber(value);
                        }}
                      />
                    </div>
                  </div>
                  <div className=" gap-4 mb mt-3 mb-6">
                    <div className="">
                      <label htmlFor="email-address" className="sr-only">
                        How many users will access the platform
                      </label>
                      <input
                        name="noOfUsers"
                        type="number"
                        required
                        className="appearance-none bg-slate-200 block w-full p-3  placeholder-gray-500 text-black-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="How many users will access the platform"
                        value={noOfUsers}
                        onChange={(e) => {
                          setNoOfUsers(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="mt-6">
                    <button
                      className={`w-full flex justify-center items-center h-12 py-3 bg-blue-50 text-white font-semibold rounded-full shadow-lg 
                        ${
                          !companyAddress ||
                          !companyName ||
                          !addressState ||
                          !zipCode ||
                          !noOfUsers ||
                          !phoneNumber
                            ? "opacity-70"
                            : ""
                        }    
                        
                        `}
                      onClick={enterpriseSignupHandler}
                      disabled={
                        !companyAddress ||
                        !companyName ||
                        !addressState ||
                        !zipCode ||
                        !noOfUsers ||
                        !phoneNumber
                      }
                    >
                      {loading ? <Loader /> : "Create Customised Plan"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default EnterpriseContact;
