import { useEffect, useRef } from "react";
import PopupStyle from "../css/Popup.module.css";
import ReactDragListView from "react-drag-listview/lib/index.js";
import { MdDragIndicator } from "react-icons/md";
import { IoCloseCircle } from "react-icons/io5";
import { aiModelDetails, aiModels } from "utils";

const ModelItem = ({ selected, model }) => {
  return (
    <div
      className={`${PopupStyle.modelItem} ${
        selected ? PopupStyle.selected : ""
      }`}
    >
      <span className="text-sm">{model.label}</span>
      <span className="text-xs hidden">{model.description}</span>
    </div>
  );
};

const ModelPopup = ({
  setOpen,
  selectedAiModel,
  setSelectedAiModel,
  responseMode,
  setResponseMode,
}) => {
  const ref = useRef(null);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...selectedAiModel];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setSelectedAiModel(data);
    },
    nodeSelector: "div",
    handleSelector: ".flex",
  };

  useEffect(() => {
    function close(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", close);
    return () => {
      document.removeEventListener("mousedown", close);
    };
  }, [ref, setOpen]);

  return (
    <div ref={ref} className={PopupStyle.modelPopup}>
      <div className={PopupStyle.header}>
        <h2>Chaining method</h2>
        <div className={PopupStyle.chainingRow}>
          <div
            className={`${PopupStyle.modelItem} ${
              responseMode === "sequential" ? PopupStyle.selected : ""
            }`}
            onClick={() => {
              setResponseMode("sequential");
            }}
          >
            Sequential
          </div>

          <div
            className={`${PopupStyle.modelItem} ${
              responseMode === "parallel" ? PopupStyle.selected : ""
            }`}
            onClick={() => {
              setResponseMode("parallel");
            }}
          >
            Parallel
          </div>
        </div>
        <p className="text-sm w-[60%] text-center">
          {responseMode === "sequential"
            ? "Processes the conversation in order.—AI #1’s output feeds into AI #2, etc.—and the final AI compiles a single, comprehensive answer."
            : "Each selected AI provides a separate response, shown in the order the AI model selected."}
        </p>
      </div>
      <div className={PopupStyle.modelContent}>
        <div className={PopupStyle.selectedModels}>
          <h2>Selected models</h2>
          <div className={PopupStyle.itemList}>
            <ReactDragListView {...dragProps}>
              {selectedAiModel &&
                selectedAiModel.length > 0 &&
                selectedAiModel.map((model) => (
                  <div className="flex flex-row gap-3 px-2 pb-2.5 cursor-pointer items-center">
                    <MdDragIndicator size={22} className="shrink-0" />
                    <div className="text-sm font-semibold opacity-80 w-20 break-words">
                      {aiModelDetails &&
                        aiModelDetails.length > 0 &&
                        aiModelDetails.filter((el) => el.value === model)[0][
                          "label"
                        ]}
                    </div>
                    <IoCloseCircle
                      className="opacity-50 cursor-pointer text-text-dark"
                      onClick={() => {
                        const index = selectedAiModel.indexOf(model);
                        const newAiModel = [...selectedAiModel];
                        newAiModel.splice(index, 1);
                        setSelectedAiModel(newAiModel);
                      }}
                    />
                  </div>
                ))}
            </ReactDragListView>
          </div>
        </div>
        <div className={PopupStyle.availableModels}>
          <h2>Available models</h2>
          {aiModels.map((model) => (
            <div className={PopupStyle.itemList}>
              <div className={PopupStyle.itemSection}>
                <h3>
                  {model.icon}
                  {model.model}
                </h3>
                <div className={PopupStyle.sectionGroup}>
                  {model.list &&
                    model.list.length > 0 &&
                    model.list.map((el) => (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedAiModel([...selectedAiModel, el.value]);
                        }}
                      >
                        <ModelItem model={el} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModelPopup;
