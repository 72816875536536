import React, { useState } from "react";
import whiteTheme from "../../assets/white-theme.png";
import blackTheme from "../../assets/black-theme.png";

const Appearance = ({ theme, updateTheme }) => {
  const [selTheme, setTheme] = useState(theme);
  return (
    <div>
      <div className=" mb-5 text-2xl">Appearance</div>
      <div className="flex flex-row gap-4 mt-4 ">
        <div
          className={`bg-black-200 px-6 pt-6 rounded-lg cursor-pointer
            ${selTheme === "light" ? "border-2 border-blue-50" : ""}
            `}
          onClick={() => {
            setTheme("light");
          }}
        >
          <img src={whiteTheme} className="w-30 h-32" />
          <div className="mb-6 mt-2 font-sans text-[15px] font-semibold text-center">
            Light mode
          </div>
        </div>
        <div
          className={`bg-black-200 px-6 pt-6 rounded-lg cursor-pointer
            ${selTheme === "dark" ? "border-2 border-blue-50" : ""}
            `}
          onClick={() => {
            setTheme("dark");
          }}
        >
          <img src={blackTheme} className="w-30 h-32" />
          <div className="mb-6 mt-2 font-sans text-[15px] font-semibold text-center">
            Dark mode
          </div>
        </div>
      </div>
      <button
        className={`w-full mt-8 flex justify-center h-12 items-center  bg-blue-50 text-white  rounded-full shadow-lg hover:from-purple-700 hover:to-indigo-700 transition

`}
        onClick={() => updateTheme(selTheme)}
      >
        Update Theme
      </button>
    </div>
  );
};

export default Appearance;
