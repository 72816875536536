import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAPIBaseUrl } from "../../utility/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { faqs, plans } from "../../utils";
import { FiPlus, FiMinus } from "react-icons/fi";

// const stripePromise = loadStripe(
//   "pk_test_51Q0ojDHG7saj5CIN9MTIVlUg8CT7OnuOvRNN7Deo1i7uOLbVlr2HTDZoyCsPxfv7mLlK4ko6dKiB1wRYr3cnL1yF00px5khdl9"
// );


const stripePromise = loadStripe(
  "pk_live_51Q0ojDHG7saj5CINiX3EaCv8ORQTIb9nyOaQvmQ3R0vs7LkuuQtqfH13vzlFsim7Gk0yAiJMbb4CK8GtHDPSlJ0300fE1xnbYY"
);

const Billing = () => {
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("starter");
  const location = useLocation();
  const [openIndex, setOpenIndex] = useState(null);
  const navigate = useNavigate(0);
  const queryParams = new URLSearchParams(location.search);
  const userType = queryParams.get("user-type") || "individual";

  const { currentUser } = useSelector((state) => state.auth);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCheckout = async (selectedPlan) => {
    setSelectedPlan(selectedPlan);
    if (selectedPlan === "custom") {
      navigate("/contact-us");
      return;
    }
    setLoading(true);

    let requestData = {
      email: currentUser.email,
      plan: selectedPlan,
    };

    try {
      const { data } = await axios.post(
        `${getAPIBaseUrl()}/create-checkout-session`,
        requestData
      );

      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to initiate checkout");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className=" bg-black-1100 px-5">
      <div className=" w-full pt-14">
        <div className="text-4xl text-white font-bold pb-1 text-center">
          AI chat made affordable
        </div>
        <div className="text-white opacity-60 text-base  text-center mb-8">
          Pricing plans for every budget
        </div>
        <div className="h-fit  flex flex-col justify-center lg:flex-row  lg:space-x-8 space-y-6 lg:space-y-0">
          {plans.map((plan) => (
            <PlanCard
              value={plan.value}
              title={plan.title}
              price={plan.price}
              trialInfo={plan.trialInfo}
              features={plan.features}
              selected={selectedPlan === plan.value}
              onSelect={() => setSelectedPlan(plan.value)}
              handleCheckout={handleCheckout}
            />
          ))}
        </div>
        <div className="max-w-4xl mx-auto px-6 py-12">
          <h2 className="text-3xl font-bold text-center my-8 text-white ">
            Frequently asked questions
          </h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="border-b border-white border-opacity-70 pb-6 cursor-pointer"
              >
                <div
                  onClick={() => toggleAccordion(index)}
                  className="flex justify-between items-center"
                >
                  <h3
                    className={`text-lg font-medium ${
                      openIndex === index ? "text-white" : "text-slate-50"
                    }`}
                  >
                    {faq.question}
                  </h3>
                  <span>
                    {openIndex === index ? (
                      <FiMinus className="text-white text-xl" />
                    ) : (
                      <FiPlus className="text-white text-xl" />
                    )}
                  </span>
                </div>
                {openIndex === index && (
                  <p className="mt-2 text-slate-50 text-opacity-60">
                    {faq.answer}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const PlanCard = ({
  title,
  price,
  features,
  selected,
  onSelect,
  value,
  description,
  handleCheckout,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-col justify-between text-white p-8 w-full lg:w-[20%] shadow-lg rounded-lg cursor-pointer transition transform ${
        selected
          ? "border-4 border-white scale-105 bg-black-600"
          : "border border-white bg-black-600"
      } hover:border-white`}
      onClick={onSelect}
    >
      <div>
        <h3 className="text-2xl font-bold mb-2">{title}</h3>
        <>
          <p className="text-3xl font-bold my-4">{price}</p>
        </>

        <div className="text-gray-400 text-base mb-6">
          {features &&
            features.length > 0 &&
            features.map((feature, index) => (
              <div key={index} className="flex items-center mb-2">
                <span className="mr-2 text-green-400">✓</span> {feature}
              </div>
            ))}
        </div>
      </div>
      <button
        className={`w-full items-end  h-12 items-center  bg-blue-50 text-white font-semibold rounded-full shadow-lg hover:from-purple-700 hover:to-indigo-700 transition
 cursor-pointer`}
        onClick={() => handleCheckout(value)}
      >
        Select Plan
      </button>
    </div>
  );
};

export default Billing;
