import React, { useState } from "react";
import { faqs, plans } from "../../utils";
import { FiPlus, FiMinus } from "react-icons/fi";

const PlanCard = ({
  title,
  price,
  trialInfo,
  features,
  selected,
  onSelect,
  isCurrent,
  value,
  description,
}) => {
  console.log("description", description + "..." + value);
  return (
    <div
      className={`flex flex-col text-white p-8 w-full lg:w-[20%] shadow-lg rounded-lg cursor-pointer transition transform ${
        selected
          ? "border-4 border-white scale-105 bg-black-600"
          : "border border-white bg-black-600"
      } hover:border-white`}
      onClick={onSelect}
    >
      <h3 className="text-2xl font-bold mb-2">{title}</h3>
      {value !== "contact" && (
        <>
          <p className="text-3xl font-bold my-4">{price}</p>
        </>
      )}

      <div className="text-gray-400 text-base mb-6">
        {features &&
          features.length > 0 &&
          features.map((feature, index) => (
            <div key={index} className="flex items-center mb-2">
              <span className="mr-2 text-green-400">✓</span> {feature}
            </div>
          ))}
      </div>
    </div>
  );
};

const UserPlans = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className=" bg-black-1100 px-5">
      <div className=" w-full pt-14">
        <div className="text-4xl text-white font-bold pb-1 text-center">
          AI chat made affordable
        </div>
        <div className="text-white opacity-60 text-base pt-2 text-center mb-14">
          Pricing plans for every budget
        </div>
        <div className="h-fit  flex flex-col justify-center lg:flex-row  lg:space-x-8 space-y-6 lg:space-y-0">
          {plans.map((plan) => (
            <PlanCard
              value={plan.value}
              title={plan.title}
              price={plan.price}
              trialInfo={plan.trialInfo}
              features={plan.features}
              description={plan?.description}
            />
          ))}
        </div>
        <div className="max-w-4xl mx-auto px-6 py-12">
          <h2 className="text-3xl font-bold text-center my-8 text-white ">
            Frequently asked questions
          </h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="border-b border-white border-opacity-70 pb-6 cursor-pointer"
              >
                <div
                  onClick={() => toggleAccordion(index)}
                  className="flex justify-between items-center"
                >
                  <h3
                    className={`text-lg font-medium ${
                      openIndex === index ? "text-white" : "text-slate-50"
                    }`}
                  >
                    {faq.question}
                  </h3>
                  <span>
                    {openIndex === index ? (
                      <FiMinus className="text-white text-xl" />
                    ) : (
                      <FiPlus className="text-white text-xl" />
                    )}
                  </span>
                </div>
                {openIndex === index && (
                  <p className="mt-2 text-slate-50 text-opacity-60">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPlans;
